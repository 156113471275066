import { get } from 'idb-keyval';
import React, { type CSSProperties, useEffect, useState } from 'react';
import { db, getSessions, getUsers } from './db/db';
import { colors } from '../components/ui/colors'
import { type Session } from './CubDashboardAdmin'
import Modal from '../components/modal'
import { PopupButton } from 'react-calendly'
import styled from 'styled-components'

const ScheduleContainer = styled.div`
  & button {
    padding: 10px 60px;
    margin-top: -35px !important;
  }
`

export const classes = [
  'Introductions, Schedules, and How the Internet Works',
  'How Computers Work, What are Programming Languages, Intro to Algorithms',
  'Intro to Scratch by MIT',
  'Building your First Game in Scratch - Balloon Pop!',
  'Second Scratch Game - Runaway Apple',
  'Design & Build Your Own Game',
  'Introduction to Python',
  'Variables, Random Numbers, & Coin Flips',
  'Building a Text Based Game w/ Python',
  'How do Websites Works?',
  'Introduction to HTML',
  'Launching your First Website!',
  'Introduction to CSS',
  'Introduction to JavaScript',
  'Creating Interactive Websites',
  'Final Project Part 1',
  'Final Project Part 2',
  'Final Project Part 3'
]

export interface User {
  name: string
  email: string
}

function Dashboard (): JSX.Element {
  const [email, setEmail] = useState('');
  const [user, setUser] = useState<User>();
  const [sessions, setSessions] = useState<Session[]>();
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState<string>();

  useEffect(() => {
    void get('email').then((e: string) => {
      e?.length > 0 ? setEmail(e) : (window.location.href = '/cubs/login');
    });
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (email) {
      void getUsers(db).then((users) => {
        setUser(users.find((user: { email: string }) => user.email === email));
      });
    }
  }, [email]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (email && user) {
      void getSessions(db).then((allSessions) => {
        setSessions(allSessions.filter((allSession: Session) => user.email === allSession.email).sort(function (a: Session, b: Session) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return Number(new Date(b.date)) - Number(new Date(a.date));
        }));
      });
    }
  }, [email, user]);
  // const sessions = [
  //   {
  //     date: '2023-01-01',
  //     title: 'Introduction to Algebra',
  //     summary: 'Basic concepts of algebra',
  //     suggestions: 'Review multiplication tables.'
  //   }
  // ];

  return (
    <>
      <div style={{ ...styles.container, display: 'flex', background: 'black', color: 'white', justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px', paddingBottom: '10px' }}>
        <p>codetiger</p>
        <p style={{ background: colors.orange, color: 'white', padding: '10px 20px', borderRadius: '20px' }}>{email}</p>
      </div>
      <div style={{ ...styles.container, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ flexGrow: '1', borderRight: '1px solid black', fontSize: '2rem', padding: '20px 0' }}>
          Tiger Cub Bootcamp
        </div>
        <div style={{ width: '25%', borderRight: '1px solid black', textAlign: 'center', fontSize: '2rem', padding: '20px 0' }}>
          {user?.name}
        </div>
        <div style={{ width: '25%', textAlign: 'right', fontSize: '2rem' }}>
          {/* {((sessions?.length ?? 0) / classes.length * 100).toFixed(2)}% */}
          <ScheduleContainer>
            <PopupButton
                url="https://calendly.com/codetiger/cubs"
                /*
                  * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                  * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                  */
                rootElement={
                  document.getElementById('root') as HTMLElement
                }
                text="Schedule Next Class"
              />
            </ScheduleContainer>
        </div>
      </div>
      <div style={{ ...styles.container, display: 'flex', flexDirection: 'row', alignItems: 'center', background: 'black', minHeight: '225px' }}>
        <div style={{ width: '40%', background: 'white', padding: '20px 30px', borderRadius: '10px' }}>
          <h2>Latest Class</h2>
          {(((sessions?.[0]?.title) != null)
            ? <>
            <p>{sessions?.[0].title}</p>
            {((sessions?.[0].date) != null) && <p>{new Date(sessions?.[0].date).toDateString()}</p>}
          </>
            : <p>Here you&apos;ll find information about your student&apos;s latest class</p>)}
        </div>
        <div style={{ background: 'transparent', padding: '0', textAlign: 'center', width: '270px', marginLeft: '-85px', zIndex: '10' }}>
          <img src='/images/tigercub.png' width="350px" />
        </div>
        <div style={{ width: '40%', background: 'white', padding: '20px 30px', borderRadius: '10px' }}>
          <h2>Analysis</h2>
          {sessions?.[0]?.summary && (<><p>Summary: {sessions?.[0].summary}</p>
          {((sessions?.[0].suggestions) !== '') ? <p>Suggestions: {sessions?.[0].suggestions}</p> : <></>}</>)}
        </div>
      </div>
      <div style={styles.container}>
        <div style={styles.sessionsContainer}>
          {classes.map((title) => {
            const completed = sessions?.find(session => session.title === title) != null
            return (
            <div key={title} style={styles.sessionCard as CSSProperties}>
              {completed ? <p style={{ color: 'green', marginBlockEnd: '0' }}>Completed</p> : <></> }
              <h2 style={styles.sessionTitle}>{title}</h2>
              {completed && <button onClick={() => { setTitle(title); setModalOpen(true) }} style={{ position: 'absolute', bottom: '10px', right: '10px' }}>Details</button>}
            </div>
            )
          })}
        </div>
      </div>

      <Modal isOpen={modalOpen} onClose={() => { setModalOpen(false); }}>
        <h2>{title}</h2>
        <p>Date: {sessions?.find(session => session.title === title)?.date}</p>
        <p>Summary: {sessions?.find(session => session.title === title)?.summary}</p>
        <p>Suggestions: {sessions?.find(session => session.title === title)?.suggestions}</p>
        <button onClick={() => { setModalOpen(false); }}>close</button>
      </Modal>
    </>
  );
}

const styles = {
  container: {
    padding: '0 5vw',
    color: '#333'
  },
  header: {
    borderBottom: '1px solid #ddd',
    paddingBottom: '10px',
    marginBottom: '20px'
  },
  progressContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  progress: {
    width: '100%'
  },
  sessionsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '20px',
    marginTop: '30px'
  },
  sessionCard: {
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '15px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    position: 'relative'
  },
  sessionTitle: {
    // color: '#0056b3'
    fontSize: '1.25rem'
  }
};

export default Dashboard;
