import React from 'react';
import { Orange } from '../App';
import '../styles/cubs.css';

export function CubsHome (): JSX.Element {
  return (
    <div className='cub-home'>
      <nav>
        code<Orange>tiger</Orange> cubs
      </nav>
      <section
        style={{
          display: 'flex',
          gap: '10px',
          flexWrap: 'wrap',
          alignItems: 'center'
        }}
      >
        <img
          src="/images/tigercub.png"
          width={120}
          style={{ marginLeft: '-10px' }}
        />
        <div>
          <h1>Empower Your Child&apos;s Future with Coding Mastery</h1>
          <p style={{ margin: '10px 0' }}>
            Unlock Limitless Potential with Personalized, One-on-One Coding
            Education
          </p>
        </div>
      </section>

      <section>
        <h2>Why Choose Us?</h2>
        <p>
          In a world where technology leads the way, coding isn&apos;t just a
          skill — it&apos;s a language of the future. Our unique coding courses
          for kids stand apart by offering personalized, one-on-one education
          from real senior software engineers. Through our master-student model,
          we don&apos;t just teach coding; we inspire a lifelong passion for
          innovation and problem-solving.
        </p>
      </section>

      <section>
        <h2>Our Programs</h2>
        <div className="course-card">
          <h3>Coding Bootcamp: From Novice to Expert</h3>
          <p>
            Dive into the world of coding with our structured Bootcamp, designed
            to guide your child from the basics to advanced concepts in the most
            in-demand languages: JavaScript and Python. Each level—beginner,
            intermediate, and advanced—is tailored to unlock your child&apos;s
            potential at their own pace.
          </p>
          <div style={{ display: 'flex', gap: '10px 25px', flexWrap: 'wrap' }}>
            <a href="/cubs/beginner" className="cta-link">
              Beginner Course
            </a>
            <a href="/cubs/intermediate" className="cta-link">
              Intermediate Course
            </a>
            <a href="/cubs/advanced" className="cta-link">
              Advanced Course
            </a>
          </div>
        </div>
        <div className="course-card">
          <h3>Digital Business Course: Launch a Real Business in 4 Weeks</h3>
          <p>
            Imagine your child not just learning to code but applying those
            skills to launch their very own digital business. Our 4-week course
            equips young minds with the essentials of business planning, digital
            marketing, and web development, culminating in the launch of a real
            business project.
          </p>
          <div style={{ display: 'flex', gap: '0 25px', flexWrap: 'wrap' }}>
            <a href="/cubs/business" className="cta-link">
              Start a business today!
            </a>
          </div>
        </div>
        <div className="course-card">
          <h3>AI Course: The Future of Technology</h3>
          <p>
            Stay ahead with our upcoming AI course, designed to introduce your
            child to the cutting-edge world of artificial intelligence. Learn
            how to use the tools that are disrupting businesses
          </p>
          <p className="coming-soon">Coming Soon</p>
        </div>
      </section>

      <section className='mobile-center'>
        <h2>
          The Code<Orange>tiger</Orange> Difference
        </h2>
        <div className='mobile-center' style={{ display: 'flex', gap: '0 20px', flexWrap: 'wrap' }}>
          <div className='data-card'>
            <h3>Master - Student Model</h3>
            <p>Each student is paired with a real senior software engineer</p>
          </div>
          <div className='data-card'>
            <h3>Modern technologies</h3>
            <p>We teach what the best companies in the world use</p>
          </div>
          <div className='data-card'>
            <h3>Holistic Learning Science</h3>
            <p>Cutting edge techniques that maximize understanding</p>
          </div>
          <div className='data-card'>
            <h3>Artificial Intelligence</h3>
            <p>Hyper-personalized curriculums optimized for fun</p>
          </div>
        </div>
      </section>

      <footer>
        code<Orange>tiger</Orange> cubs &copy; 2024
      </footer>
    </div>
  );
}
