import * as React from 'react'
// icon:accessibility | Radix Icons https://icons.radix-ui.com/ | WorkOS

export function A11yIcon (props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg fill="none" viewBox="0 0 15 15" height="1em" width="1em" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.877 7.5a6.623 6.623 0 1113.246 0 6.623 6.623 0 01-13.246 0zM7.5 1.827a5.673 5.673 0 100 11.346 5.673 5.673 0 000-11.346zM7.125 9c-.055.127-.793 2.96-.793 2.96a.5.5 0 11-.966-.26s.88-2.827.88-3.43V6.801l-1.958-.525a.5.5 0 11.258-.966s1.654.563 2.3.563h1.309c.645 0 2.298-.563 2.298-.563a.5.5 0 11.26.966l-1.966.527V8.27c0 .603.88 3.427.88 3.427a.5.5 0 01-.966.259S7.92 9.127 7.869 9c-.05-.127-.219-.127-.219-.127h-.307s-.173 0-.218.127zM7.5 5.12a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z"
        clipRule="evenodd"
      />
    </svg>
  )
}
