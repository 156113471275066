import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from './ui/colors';
import { DONT_SHOW_SECONDARY_NAV } from '../constants';
import { Link as RouterLink } from '../../node_modules/react-router-dom/dist/index';

const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 99;
`;

const NavContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.darkblue};
  color: white;
`;
const Home = styled(RouterLink)`
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const Link = styled(RouterLink)`
  color: white;
  text-decoration: none;
`;

const CTALink = styled.a`
  padding: 15px 20px;
  background-color: ${colors.orange};
  color: white;
  font-weight: bold;
`;

const ExternalLink = styled.a`
  text-decoration: none;
  color: white;
`;

const SecondaryNav = styled.div`
  display: flex;
  background: #5a5a5a;
  justify-content: center;
  padding: 15px 0px;
`;

export function Nav (): JSX.Element {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange (): void {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const pathname = window.location.pathname;

  const showSecondary = !DONT_SHOW_SECONDARY_NAV.includes(pathname);

  return (
    <>
      <StickyContainer>
        <NavContainer style={isMobile ? { padding: '30px 20px' } : undefined}>
          <div>
            <Home to="/">
              code<span style={{ color: '#F66B0E' }}>tiger</span>
            </Home>
          </div>
          {!isMobile && (
            <Links>
              <Link to="/how-it-works">how it works</Link>
              <Link to="/about">about</Link>
              <Link to="/jobs">jobs</Link>
              <ExternalLink href="https://blog.codetiger.io">blog</ExternalLink>
              <Link to="/contact">contact</Link>
              <ExternalLink
                href="https://calendly.com/princetoncrypto/connect"
                target="_blank"
                rel="noreferrer"
              >
                <CTALink>start free trial</CTALink>
              </ExternalLink>
            </Links>
          )}
          {isMobile && (
            <>
              <Links>
                <ExternalLink
                  href="https://calendly.com/princetoncrypto/connect"
                  target="_blank"
                  rel="noreferrer"
                >
                  <CTALink>start free trial</CTALink>
                </ExternalLink>
              </Links>
            </>
          )}
        </NavContainer>
      </StickyContainer>
      {isMobile && showSecondary && (
        <SecondaryNav>
          <Links>
            <Link to="/about">about</Link>
            <Link to="/how-it-works">how it works</Link>
            <Link to="/jobs">jobs</Link>
            <ExternalLink href="https://blog.codetiger.io">blog</ExternalLink>
            <Link to="/contact">contact</Link>
          </Links>
        </SecondaryNav>
      )}
    </>
  );
}
