import React from 'react';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from '../node_modules/react-router-dom/dist/index';
import { colors } from './components/ui/colors';
// import { Course } from './pages/Course';
import { Footer } from './components/footer';
import ScrollToTop from './components/ui/scrollToTop';
import { About } from './pages/About';
import { Accessibility } from './pages/Accessibility';
import { Charity } from './pages/Charity';
import { Contact } from './pages/Contact';
import { Home } from './pages/Home';
import { HowItWorks } from './pages/HowItWorks';
import { Jobs } from './pages/Jobs';
import { Nav } from './components/nav';
// import { NFTCerts } from './pages/NFTCerts';
import { Privacy } from './pages/Privacy';
import { SchedulePage } from './pages/Schedule';
import { Terms } from './pages/Terms';
import { ThankYou } from './pages/ThankYou';
import Profile from './pages/Profile';
// import Home from './pages/NewHomePage'
import { LeadGen2 } from './pages/LeadGen2';
import { CubsLanding } from './pages/CubsLanding'
// import { CubsSyllabus } from './pages/CubsSyllabus'
import { CubsMemberSyllabus } from './pages/CubsMemberSyllabus'
import { SiteGenerator } from './pages/SiteGenerator'
import { Site } from './pages/Site'
import { SiteEdit } from './pages/SiteEdit'
import SignUp from './pages/Signup'
import Dashboard from './pages/CubDashboard'
import Login from './pages/Login'
import CubDashboardAdmin from './pages/CubDashboardAdmin'
import { CubsHome } from './pages/CubsHome'
import { CubsLandingIntermediate } from './pages/CubsLandingIntermediate'
import { CubsLandingAdvanced } from './pages/CubsLandingAdvanced'
import { CubsLandingBusiness } from './pages/CubsLandingBusiness'
// import { LeadGen } from './pages/LeadGen';

export const Orange = styled.span`
  color: ${colors.orange};
`;

function App (): JSX.Element {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <ScrollToTop />
              <Nav />
              <Home />
              <Footer />
            </>
          }
        />
        <Route
          path="/about"
          element={
            <>
              <ScrollToTop />
              <Nav />
              <About />
              <Footer />
            </>
          }
        />
        <Route
          path="/jobs"
          element={
            <>
              <ScrollToTop />
              <Nav />
              <Jobs />
              <Footer />
            </>
          }
        />
        <Route
          path="/how-it-works"
          element={
            <>
              <ScrollToTop />
              <Nav />
              <HowItWorks />
              <Footer />
            </>
          }
        />
        <Route
          path="/contact"
          element={
            <>
              <ScrollToTop />
              <Nav />
              <Contact />
              <Footer />
            </>
          }
        />
        <Route
          path="/thank-you"
          element={
            <>
              <ScrollToTop />
              <Nav />
              <ThankYou />
              <Footer />
            </>
          }
        />
        <Route
          path="/privacy"
          element={
            <>
              <ScrollToTop />
              <Nav />
              <Privacy />
              <Footer />
            </>
          }
        />
        <Route
          path="/syllabus"
          element={
            <>
              <ScrollToTop />
              <Nav />
              <SchedulePage />
              <Footer />
            </>
          }
        />
        <Route
          path="/charity"
          element={
            <>
              <ScrollToTop />
              <Nav />
              <Charity />
              <Footer />
            </>
          }
        />
        <Route
          path="/accessibility"
          element={
            <>
              <ScrollToTop />
              <Nav />
              <Accessibility />
              <Footer />
            </>
          }
        />
        <Route
          path="/terms"
          element={
            <>
              <ScrollToTop />
              <Nav />
              <Terms />
              <Footer />
            </>
          }
        />
        <Route path="/course" element={<LeadGen2 />} />
        <Route
          path="/profile"
          element={
            <>
              <Profile />
            </>
          }
        />
        {/* <Route path="/nft-certificates" element={<NFTCerts />} /> */}
        <Route path="/cubs" element={<CubsHome />} />
        <Route path="/cubs/beginner" element={<CubsLanding />} />
        <Route path="/cubs/intermediate" element={<CubsLandingIntermediate />} />
        <Route path="/cubs/advanced" element={<CubsLandingAdvanced />} />
        <Route path="/cubs/business" element={<CubsLandingBusiness />} />
        {/* <Route path="/cubs/syllabus" element={<CubsSyllabus />} /> */}
        <Route path="/cubs/syllabus" element={<CubsMemberSyllabus />} />
        <Route path="/build-a-site" element={<SiteGenerator />} />
        <Route path="site/:id" element={<Site />} />
        <Route path="site/:id/edit" element={<SiteEdit />} />
        <Route path="/cubs/access" element={<SignUp />} />
        <Route path="/cubs/dashboard" element={<Dashboard />} />
        <Route path="/cubs/dashboard/admin/password" element={<CubDashboardAdmin />} />
        <Route path="/cubs/login" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
