import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DollarIcon } from '../components/icons/dollarIcon';
import { numberWithCommas } from '../utils';
import { PageContainer } from '../components/ui/basics';

const JobsContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`

const JobCard = styled.div`
  width: 270px;
  padding: 20px 30px;
  border: 1px solid black;

  &:hover {
    transform: scale(1.03);
  }
`

const FlexHorizontalCenter = styled.div`
  display: flex;
  align-items: center;
`

const JobLink = styled.a`
  text-decoration: none;
  color: black
`

const LoadingContainer = styled.div`
  width: 100vw;
  height: 50vh;
  display:flex;
  justify-content: center;
  align-items: center;
`

interface AdzunaJob {
  id: string
  title: string
  company: {
    display_name: string
  }
  location: {
    area: string[]
  }
  salary_min: number
  salary_max: number
  redirect_url: string
}

export function Jobs (): JSX.Element {
  const [data, setData] = useState<AdzunaJob[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    fetch(
      'https://api.adzuna.com/v1/api/jobs/us/search/1?app_id=afb03943&app_key=21b3d53d902ae8e0355d29453b90d39c&results_per_page=20&what=react,remote&content-type=application/json'
      // eslint-disable-next-line @typescript-eslint/promise-function-async
    )
      .then(async (res) => await res.json())
      .then(
        (result) => {
          setLoading(false)
          setData(
            result.results.filter(
              (job: AdzunaJob) =>
                !job.title.toLowerCase().includes('react native')
            ).sort(
              (a: AdzunaJob, b: AdzunaJob) => a.salary_min < b.salary_min
            )
          )
        },
        (error) => {
          setLoading(true)
          setError(error)
        }
      )
  }, [])
  return loading
    ? (
    <LoadingContainer>
      <div style={{ textAlign: 'center' }}>
      ⏳<br />
      loading
      </div>
      </LoadingContainer>
      )
    : error
      ? (
    <PageContainer>error</PageContainer>
        )
      : (
    <PageContainer>
    <h1 style={{ textAlign: 'center', padding: '0 10px' }}>Find Your Next Remote Opportunity</h1>
      <JobsContainer>
        {data?.map((job) => {
          return (
            <JobLink key={job.id} href={job.redirect_url} target='_blank' rel="noreferrer">
            <JobCard>
              <p style={{ marginTop: '0' }}>{job.company.display_name}</p>
              <p>{job.title}</p>
              <p>
                {(job.location.area[3] !== undefined) && job.location.area[3] + ' ,'} {(job.location.area[1] !== undefined) && job.location.area[1]}
              </p>
              <FlexHorizontalCenter>
                <DollarIcon style={{ marginRight: '10px' }} />
                ${job.salary_max === job.salary_min
                  ? (
                      numberWithCommas(job.salary_min)
                    )
                  : (
                  <>
                    {numberWithCommas(job.salary_min)} - {numberWithCommas(job.salary_max)}
                  </>
                    )}
              </FlexHorizontalCenter>
            </JobCard>
            </JobLink>
          )
        })}
      </JobsContainer>
    </PageContainer>
        )
}
