import React, { type CSSProperties, useState, type FormEvent } from 'react';
import { set } from 'idb-keyval'

function Login (): JSX.Element {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    await Promise.all([set('email', email)]).then(() => { window.location.href = '/cubs/dashboard' })
  };

  const cardStyle = {
    backgroundColor: '#f3f3f3',
    padding: '20px',
    margin: '0 auto',
    marginTop: '50px',
    width: '300px',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    textAlign: 'center'
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh'
  };

  const inputStyle = {
    margin: '10px 0',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    width: '95%'
  };

  return (
    <div style={containerStyle as CSSProperties}>
      <div>
      <h1>Login to Tiger Cub Dashboard</h1>
      <div style={cardStyle as CSSProperties}>
        <form onSubmit={(e) => { void handleSubmit(e) }}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => { setEmail(e.target.value); }}
            style={inputStyle}
          />
          <button type="submit" style={{ cursor: 'pointer', padding: '10px 20px', marginTop: '10px' }}>Login</button>
        </form>
      </div>
      </div>
    </div>
  );
}

export default Login;
