import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { A11yIcon } from '../components/icons/a11yIcon';
import { Alert } from '../components/alert';
import { Center, Column, Row } from './About';
import { ChatIcon } from '../components/icons/chatIcon';
import { colors } from '../components/ui/colors';
import {
  FlexContainer,
  Heading2,
  Heading3,
  LinkButton,
  Text as PText1
} from '../components/ui/basics';
import { GraduateIcon } from '../components/icons/graduateIcon';
import { Orange } from '../App';
import { PricingCards } from '../components/pricingCards';
import { ReactIcon } from '../components/icons/reactIcon';
import { SubscriptionForm } from '../components/subscriptionForm';
import { TeamIcon } from '../components/icons/teamIcon';
import { TypescriptIcon } from '../components/icons/typescriptIcon';

const PText = styled(PText1)`
  font-size: 1.3rem;
`;

export const HeaderVerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 35vh;
  margin-top: 60px;
`

// const VerticalContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   height: 60vh;
// `

// const HorizontalContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   gap: 15px;
//   margin-bottom: 20px;
// `

export const Heading = styled.h1`
  margin: 0;
  font-size: 5rem;
  @media only screen and (max-width: 600px) {
    font-size: 4rem;
  }
`

const Text = styled.p`
  font-size: 2rem;
  letter-spacing: 0em; /* Adjust as needed */
  width: 53ch;
  animation: typing 4s steps(53);
  white-space: nowrap;
  overflow: hidden;
  // border-right: 3px solid ${colors.orange};
  font-family: monospace;
  min-height: 40px;

  @keyframes typing {
    from {
      width: 0;
    }
  }

  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
`

const Container = styled.div`
  padding: 50px 10px;
`

const CardContainer = styled.div`
  max-width: 300px;
  min-width: 300px;
`

const PricingContainer = styled.div`
  // display: flex;
  // justify-content: center;
  // gap: 30px;
  padding: 50px 20px;
`

const NewsletterContainer = styled.div`
  padding: 0px;
  height: 600px;
  background-image: url('/images/banner4.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 150px -250px;
`

const NewletterDesc = styled.div`
  min-height: 580px;
  background: white;
  padding: 20px 75px 0;
  @media only screen and (max-width: 600px) {
    padding: 20px 7px 0;
  }
`

const IconFlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 100px;
  top: 0;
  left: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  animation: marquee 30s linear infinite;
  z-index: 0;

  @media only screen and (max-width: 600px) {
    gap: 0px;
    animation: marquee 10s linear infinite;
  }

  @keyframes marquee {
    0% {
      left: 100%;
    }
    100% {
      left: -100%;
    }
  }
`

const MarqueeContainer = styled.div`
  position: relative;
  height: 75px;
  overflow: hidden;
  line-height: 30px;
  z-index: 0;
`

const IconFlexContainer2 = styled.div`
  display: flex;
  align-items: center;
  gap: 100px;
  top: 0;
  left: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  animation: marquee 30s linear infinite;
  animation-delay: 15s;
  padding-left: 10px;
  z-index: 0;

  @media only screen and (max-width: 600px) {
    gap: 10px;
    animation: marquee 10s linear infinite;
    animation-delay: 5s;
  }
  @keyframes marquee {
    0% {
      left: 100%;
    }
    100% {
      left: -100%;
    }
  }
`

export const HalfContainer = styled.div`
  width: 40%;
  @media only screen and (max-width: 600px) {
    width: 100%;
    overflow: hidden;
  }
`

const SkillContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 600px) {
    flex-direction: row;
    overflow: scroll;
  }
`

const SkillSalaryContainer = styled.div`
  display: flex;
  width: 200px;
  margin: auto;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
  flex-direction: column;
  text-align: center;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    text-align: center;
  }
`
const SkillSalary = styled.div``

export function Home (): JSX.Element {
  const [width, setWidth] = useState<number>(window.innerWidth)

  function handleWindowSizeChange (): void {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768
  return (
    <>
     <Alert message={<>🎉 free 1-day react bootcamp</>} link='learn more' assistiveText=' about our free course' linkDestination='/course' />
      <HeaderVerticalContainer style={{ marginTop: '0' }}>
        <Row style={{ paddingTop: '0' }}>
          <Column style={{ paddingTop: '0' }}>
            <Center>
              <img src="/images/codetigerlogo.png" alt='logo' width="50%" />
            </Center>
          </Column>
        </Row>
        <Heading>
          code<Orange>tiger</Orange>
        </Heading>
        {!isMobile && (
          <Text>learn in-demand skills that pay 6-figures in 12 weeks</Text>
        )}
        {isMobile && (
          <PText style={{ textAlign: 'center', padding: '0 25px' }}>
            learn in-demand skills that pay<br /> 6-figures in 12 weeks
          </PText>
        )}
        <LinkButton
          href="https://calendly.com/princetoncrypto/connect"
          target="_blank"
        >
          start free trial
        </LinkButton>
      </HeaderVerticalContainer>

      <Container style={{ marginBottom: '50px', textAlign: 'center' }}>
        <Heading2>Discover a Smarter Way to Learn</Heading2>
        <FlexContainer>
          <CardContainer>
            <TeamIcon width={50} height={50} />
            <Heading3>Mentors</Heading3>
            <PText>
              meet one-on-one with an experienced industry mentor. your personal
              expert in your coding journey.
            </PText>
          </CardContainer>
          <CardContainer>
            <ChatIcon width={50} height={50} />
            <Heading3>Tutors</Heading3>
            <PText>
              your built-in problem solver, available everyday to answer
              questions on slack
            </PText>
          </CardContainer>
          <CardContainer>
            <GraduateIcon width={50} height={50} />
            <Heading3>Career Coaching</Heading3>
            <PText>
              six months of exclusive, dedicated help while you apply to jobs
              and negotiate the best offer.
            </PText>
          </CardContainer>
        </FlexContainer>
      </Container>

      <Container style={{ backgroundColor: colors.gray }}>
        <Heading2
          style={{ textAlign: 'center', marginBottom: '0px', marginTop: '0' }}
        >
          Project Based Learning
        </Heading2>
        <PText style={{ textAlign: 'center' }}>
          learn by rebuilding popular applications or your own app ideas that
          you can add to your portofolio or even generate income with
        </PText>
        {!isMobile && (
          <MarqueeContainer>
            <IconFlexContainer>
              <img src="/images/googlelogo.png" alt="google logo" height={45} />
              <img src="/images/instalogo.png" alt="instagram logo" height={55} />
              <img src="/images/redditlogo.png" alt="reddit logo" height={75} />
              <img src="/images/twitterlogo.png" alt="twitter logo" height={75} />
              <img src="/images/tiktoklogo.png" alt="tiktok logo" height={40} />
              <img src="/images/whatsapplogo.png" alt="whatsapp logo" height={75} />
            </IconFlexContainer>
            <IconFlexContainer2>
              <img src="/images/googlelogo.png" alt="google logo" height={45} />
              <img src="/images/instalogo.png" alt="instagram logo" height={55} />
              <img src="/images/redditlogo.png" alt="reddit logo" height={75} />
              <img src="/images/twitterlogo.png" alt="twitter logo" height={75} />
              <img src="/images/tiktoklogo.png" alt="tiktok logo" height={40} />
              <img src="/images/whatsapplogo.png" alt="whatsapp logo" height={75} />
            </IconFlexContainer2>
          </MarqueeContainer>
        )}
        {isMobile && (
          <FlexContainer style={{ gap: '10px' }}>
            <img src="/images/googlelogo.png" alt="google logo" height={45} />
            <img src="/images/instalogo.png" alt="instagram logo" height={55} />
            <img src="/images/redditlogo.png" alt="reddit logo" height={75} />
            <img src="/images/twitterlogo.png" alt="twitter logo" height={75} />
            <img src="/images/tiktoklogo.png" alt="tiktok logo" height={40} />
            <img src="/images/whatsapplogo.png" alt="whatsapp logo" height={75} />
          </FlexContainer>
        )}
      </Container>

      <div>
        <FlexContainer
          style={{
            marginTop: '0px',
            justifyContent: 'center',
            marginBottom: isMobile ? '25px' : ''
          }}
        >
          <HalfContainer
            style={{ textAlign: 'center', width: isMobile ? '100%' : '57%' }}
          >
            <img src="/images/banner1.jpg" alt='a black girl mentoring a black guy on how to code' width="100%" height="100%" />
          </HalfContainer>
          <HalfContainer>
            <Heading2
              style={{
                fontSize: '2.25rem',
                textAlign: 'center',
                marginBottom: isMobile ? '15px' : '35px',
                marginTop: isMobile ? '0' : '-25px'
              }}
            >
              Technologies
            </Heading2>
            <SkillContainer>
              <SkillSalaryContainer>
                <ReactIcon width={55} height={55} />
                <SkillSalary>
                  <Heading3 style={{ marginBottom: '0px' }}>React</Heading3>
                  <PText style={{ margin: '0px' }}>avg salary:<br />$108,594</PText>
                </SkillSalary>
              </SkillSalaryContainer>
              <SkillSalaryContainer>
                <TypescriptIcon width={45} height={45} />
                <SkillSalary>
                  <Heading3 style={{ marginBottom: '0px' }}>
                    TypeScript
                  </Heading3>
                  <PText style={{ margin: '0px' }}>avg salary:<br />$136,000</PText>
                </SkillSalary>
              </SkillSalaryContainer>
              <SkillSalaryContainer>
                <A11yIcon width={55} height={55} />
                <SkillSalary>
                  <Heading3 style={{ marginBottom: '0px' }}>
                    Accessibility
                  </Heading3>
                  <PText style={{ margin: '0px' }}>avg salary:<br />$93,731</PText>
                </SkillSalary>
              </SkillSalaryContainer>
            </SkillContainer>
          </HalfContainer>
        </FlexContainer>
      </div>

      <PricingContainer style={{ backgroundColor: 'white' }}>
        <Heading2 style={{ textAlign: 'center', margin: '0', color: 'black' }}>
          Pricing
        </Heading2>
        {/* <PText style={{ textAlign: 'center', margin: '10px 0' }}><Orange>(50% off)</Orange></PText> */}
        <PText
          style={{
            textAlign: 'center',
            marginBottom: '20px',
            lineHeight: '1.75rem',
            color: 'black'
          }}
        >
          with codetiger you can get a personalized education at a fraction
          of the cost
        </PText>
        <PricingCards />
      </PricingContainer>

      {isMobile && <img src="/images/banner4.jpg" alt="a red headed young white girl happy working on laptop" width="100%" />}
      <NewsletterContainer>
        <FlexContainer style={{ justifyContent: 'start', alignItems: 'start' }}>
          <div style={{ width: isMobile ? '100%' : '40%' }}>
            <NewletterDesc
              style={{
                display: 'flex',
                flexDirection: 'column',
                // alignItems: 'center',
                textAlign: isMobile ? 'center' : 'center'
              }}
            >
              <Heading2
                style={{
                  fontSize: '2.25rem',
                  marginBottom: '0px'
                }}
              >
                Newsletter
              </Heading2>
              <PText>
                our monthly newsletter features trending tech, useful coding
                tips, and the latest in job opportunities.
              </PText>
              <PText>
                as a bonus sign up today and get your first class{' '}
                <strong>free</strong>. in just one hour you&apos;ll learn how to
                build and deploy your own live website.
              </PText>
              {/* <iframe
                width="470"
                height="305"
                src="https://125b0006.sibforms.com/serve/MUIEAPxeWko_p5qdIFMYrvDXQq6Jo7uvzCLNeyVqhTXpHVrOUoUVjoeN5G7sfU6xVRHWO6K9786ww1CH80lVdBPJY4ICdPCKmKtSIHiTMVh6NA-378f5KeoCLc6ij9JdQCdJP2qPBR7aHPpR_GEm9VJ2Cxo-hsX6Z4n3CFsPETg63HhWWIUpoKupQ7VQ6xRBuSRqrv1lwddrpmGu"
                style={{
                  display: 'block',
                  marginLeft: '0',
                  marginRight: '0',
                  maxWidth: '100%',
                  border: 'none'
                }}
              ></iframe> */}
              <SubscriptionForm />
            </NewletterDesc>
          </div>
        </FlexContainer>
      </NewsletterContainer>

      {/* <BlackContainer>
        <Heading2 style={{ color: 'white', marginTop: '0', marginBottom: '30px' }}>start today</Heading2>
      <LinkButton
          href="https://calendly.com/princetoncrypto/connect"
          target="_blank"
        >
          apply today
        </LinkButton>
      </BlackContainer> */}
      {/* <VerticalContainer style={{ background: '#F8F8F8' }}>
        <Heading2>technologies</Heading2>
        <HorizontalContainer>
          <CLICard>
            <FakeMenu>
              <FakeClose />
              <FakeMin />
              <FakeZoom />
            </FakeMenu>
            <CLIContent>
              <Heading3>react</Heading3>
              <PText>avg salary: $108,594</PText>
            </CLIContent>
          </CLICard>
          <CLICard>
            <Heading3>typescript</Heading3>
            <PText>avg salary: $136,000</PText>
          </CLICard>
          <CLICard>
            <Heading3>accessibility</Heading3>
            <PText>avg salary: $93,731</PText>
          </CLICard>
        </HorizontalContainer>
      </VerticalContainer>
      <VerticalContainer>
        <Heading2>career skills</Heading2>
        <HorizontalContainer>
          <Card>
            <Heading3>problem solving</Heading3>
          </Card>
          <Card>
            <Heading3>project management</Heading3>
          </Card>
          <Card>
            <Heading3>user experience</Heading3>
          </Card>
        </HorizontalContainer>
        <HorizontalContainer>
          <Card>
            <Heading3>git</Heading3>
          </Card>
          <Card>
            <Heading3>asana</Heading3>
          </Card>
          <Card>
            <Heading3>agile/scrum</Heading3>
          </Card>
        </HorizontalContainer>
      </VerticalContainer> */}
    </>
  )
}
