import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../components/ui/colors';
import { FlexHorizontalCenter, Orange, Text } from '../components/ui/basics';
import { PopupButton } from 'react-calendly';
// import { Countdown } from '../components/countdown';

const HeadingContainer = styled.div`
  background-color: black;
  color: white;
  max-height: 280px;
  padding-top: 35px;
`;
const Container = styled.div`
  max-width: 1100px;
  margin: auto;
  padding: 0 50px;
  @media only screen and (max-width: 768px) {
    padding: 0 20px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Column1 = styled.div`
  max-width: 650px;
  padding: 0 50px 0 0;
  @media only screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
`;

const Column2 = styled.div`
  flex-grow: 1;
  max-width: 380px;
  height: 1500px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const Form = styled.div`
  height: auto;
  align-self: flex-start
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0px;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

const UnorderedList = styled.ul`
  margin-left: -20px;
  li {
    margin-bottom: 15px;
    font-size: 1.15rem;
    list-style: '✰ ';
  }
`;

const MainContent = styled.div`
  h2 {
    margin-top: 50px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 400px;
  }
`;

// const Card = styled.div`
//   border: 1px solid #d1d7db;
//   padding: 20px;
//   width: 95%;
//   h3 {
//     margin: 0;
//   }
// `;

const Card = styled.div`
  margin-top: 50px;
  border: 1px solid #d1d7db;
  padding: 20px;
  h2 {
    margin-top: 0;
  }
`;

const InstructorCard = styled.div`
  margin-top: 50px;
  border: 1px solid #d1d7db;
  padding: 20px;
  h2 {
    margin-top: 0;
  }
`;

const P = styled.p`
  line-height: 1.5;
  font-size: 1.15rem;
  @media only screen and (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const StyledText = styled(Text)`
  margin-top: 10px;
  margin-bottom: 0px;
  @media only screen and (max-width: 768px) {
    font-size: 1.1rem !important;
  }
`;

const ScheduleContainer = styled.div`
  padding: 20px 0 50px;
  text-align: center;
  & button {
    border: none;
    color: white;
    background: ${colors.orange};
    padding: 15px 20px;
    text-decoration: none;
    font-size: 1.5rem;
    border-radius: 3px;
    font-weight: bold;
    margin-top: 15px;
    cursor: pointer;
  }
`;

export function LeadGen2 (): JSX.Element {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange (): void {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 868;

  return (
    <>
      <HeadingContainer>
        <Container>
          {!isMobile && (
            <>
              <Row style={{ justifyContent: 'space-between' }}>
                <Column1 style={{ marginTop: '25px' }}>
                  {/* <Countdown /> */}
                  <h1 style={{ marginBottom: '0' }}>
                    Free 1-day React Bootcamp
                  </h1>
                  <StyledText>
                    in this course you will build an accessible and responsive
                    react application while learning the fundamentals.
                  </StyledText>
                  <Row style={{ gap: '10px' }}>
                    {/* <p>may 15-17 @ 7pm est</p> */}
                    <p>
                      taught by{' '}
                      <a
                        href="#instructor"
                        style={{ color: colors.lightorange }}
                      >
                        mir
                      </a>
                    </p>
                    <p></p>
                  </Row>
                </Column1>
                <Column2>
                  <Form>
                    <img src="/images/banner3.jpg" style={{ width: '100%' }} />
                    {/* <ContactForm /> */}
                    <ScheduleContainer>
                      <PopupButton
                        url="https://calendly.com/princetoncrypto/connect"
                        /*
                         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                         */
                        rootElement={
                          document.getElementById('root') as HTMLElement
                        }
                        text="SIGN UP TODAY"
                      />
                    </ScheduleContainer>
                  </Form>
                </Column2>
              </Row>
            </>
          )}
          {isMobile && (
            <Form style={{ color: 'black' }}>
              <img src="/images/banner3.jpg" style={{ width: '100%' }} />
              <div style={{ padding: '0 15px' }}>
                <h1 style={{ marginBottom: '0', fontSize: '2rem' }}>
                  Free 1-day React Bootcamp
                </h1>
                <Text style={{ marginTop: '10px', marginBottom: '0px' }}>
                  in this course you will build an accessible and responsive
                  react application while learning the fundamentals.
                </Text>
              </div>
              {/* <ContactForm /> */}
              <ScheduleContainer>
                <PopupButton
                  url="https://calendly.com/princetoncrypto/connect"
                  /*
                   * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                   * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                   */
                  rootElement={document.getElementById('root') as HTMLElement}
                  text="SIGN UP TODAY"
                />
              </ScheduleContainer>
            </Form>
          )}
        </Container>
      </HeadingContainer>
      <MainContent>
        <Container>
          <Column1 style={{ marginBottom: '75px' }}>
            <Card>
              <h2>What you&apos;ll learn</h2>
              <UnorderedList>
                <li>The basics of react, typescript, and next.js</li>
                <li>How to build a portfolio site</li>
                <li>How to deploy a live web application</li>
              </UnorderedList>
            </Card>
            <h2>This course includes:</h2>
            <UnorderedList style={{ marginLeft: '-5px' }}>
              <li>one on one session with a senior software engineer</li>
              <li>nft & pdf certification of completion</li>
              <li>recording of the session</li>
              <li>access to slack channel w/ dedicated support</li>
              <li>50% off on our 12-week bootcamp</li>
              <li>react + js + accessibility cheatsheets</li>
            </UnorderedList>

            <h2>Requirements</h2>
            <P>
              this course is for beginners. all you need is a laptop + pencil &
              paper
            </P>
            <p>recommended: macbook</p>

            <h2>Description</h2>
            <P>
              react.js is the most popular javascript library you can use and
              learn these days to build modern web apps and find high paying
              careers.
            </P>
            <P>
              in this course you will learn by building a react application from
              start to finish.
            </P>
            <P>
              we&apos;ll cover the fundamental that will jumpstart your journey
              with react
            </P>
            <P>
              you&apos;ll learn how to build and deploy a live react
              application.
            </P>
            <P>
              we&apos;ll then dive into the basics of javascript and react which
              will result in a functional portfolio you can share with the world
            </P>
            <div id="instructor">
              <InstructorCard>
                <h2>Instructor</h2>
                <Row style={{ alignItems: 'start', gap: '25px' }}>
                  <img src="/images/mir.jpg" width="135px" />
                  <div>
                    <Row style={{ gap: '10px' }}>
                      <P style={{ marginTop: '-10px' }}>🎓 nyu cs & math</P>
                      <P style={{ marginTop: '-10px' }}>🚀 buildspace alumi</P>
                      <P style={{ marginTop: '-10px' }}>🧑‍💻 sr. engineer</P>
                    </Row>
                    <P style={{ marginTop: '-10px' }}>
                      mir has been coding for nearly a decade. he&apos;s a
                      senior engineer with over 5 years of professional
                      experience in education, crypto, and fintech. he is also a
                      fulbright scholar and founder of code
                      <Orange>tiger</Orange>
                    </P>
                  </div>
                </Row>
              </InstructorCard>
            </div>
          </Column1>
          <h2>Reviews</h2>
          <div style={{ overflowX: 'scroll', marginBottom: '100px' }}>
            <FlexHorizontalCenter>
              <InstructorCard style={{ marginTop: 0, minWidth: '270px' }}>
                <p style={{ marginTop: '0' }}>⭐️⭐️⭐️⭐️⭐️</p>
                <p>Raina Petrova</p>
                <p>Head of Engineering @ Coinme</p>
                <p>
                  Mir&apos;s as solid and reliable as they come; I trust him. As
                  an engineer, he&apos;s very smart and very capable. I
                  can&apos;t say enough good things.
                </p>
              </InstructorCard>
              <InstructorCard style={{ marginTop: 0, minWidth: '270px' }}>
                <p style={{ marginTop: '0' }}>⭐️⭐️⭐️⭐️⭐️</p>
                <p>Kevin Kabore</p>
                <p>Software Engineer @ Peloton</p>
                <p>
                  I definitely recommend this bootcamp to anyone wanting to get
                  started with software engineering and development.
                </p>
              </InstructorCard>
              <InstructorCard style={{ marginTop: 0, minWidth: '270px' }}>
                <p style={{ marginTop: '0' }}>⭐️⭐️⭐️⭐️⭐️</p>
                <p>Josh Motley</p>
                <p>Software Engineer @ Instagram</p>
                <p>
                  Top tier engineers that were able to explain React,
                  Typescript, and Nextjs in an easy to understand way.
                </p>
              </InstructorCard>
              {/* <InstructorCard style={{ marginTop: 0, minWidth: '270px' }}>
                  <p>Josh Motley</p>
                  <p>Software Engineer @ Instagram</p>
                </InstructorCard> */}
            </FlexHorizontalCenter>
          </div>
        </Container>
      </MainContent>
    </>
  );
}
