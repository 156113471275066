import * as emailjs from '../../node_modules/@emailjs/browser/es/index';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Center, Mission } from './About';
import { colors } from '../components/ui/colors';
import { Text } from '../components/ui/basics';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const ContactContainer = styled.div`
  
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.blue};
  padding: 20px 30px 0;
  margin: 30px 0px 50px;
  @media only screen and (max-width: 600px) {
    border: none;
  }
`;
const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 10px;
    font-size: 1.5rem;
  }
  input {
    height: 30px;
    margin-bottom: 20px;
    width: 300px;
    font-size:1.2rem;
  }
  textarea {
    height: 75px;
  }
  input[type='submit'] {
    width: 100%;
    height: 55px;
    margin-top: 15px;
    padding: 15px 20px;
    border: none;
    font-size: 1.25rem;
    background-color: ${colors.orange};
    color: white;
    font-weight: bold;
    &:hover {
      cursor: pointer;
    }
  }
`;

const SuccessContainer = styled.div`
  padding-top: 50px;
  height: 53vh;
`;

export function Contact (): JSX.Element {
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: React.FormEvent): void => {
    e.preventDefault();
    setSubmitting(true);
    emailjs
      .sendForm(
        'service_94og1jb',
        'template_13q4okd',
        form.current as HTMLFormElement,
        'E1HCO6unkGiVI5YvP'
      )
      .then(
        (result: { text: any }) => {
          setSuccess(true);
          console.log(result.text);
        },
        (error: { text: any }) => {
          setSubmitting(false);
          setError(error.text);
          console.log(error.text);
        }
      );
  };
  return (
    <Container>
      <Center>
        {!success && (
          <ContactContainer>
            <Mission style={{ marginTop: '25px' }}>Contact</Mission>
            <FormContainer>
              {error !== '' && <Text style={{ color: 'red' }}>{error}</Text>}
              <form ref={form} onSubmit={sendEmail}>
                <FormFields>
                  <label>Name</label>
                  <input type="text" name="user_name" />
                  <label>Email</label>
                  <input type="email" name="user_email" />
                  <label>Message</label>
                  <textarea name="message" />
                  <input type="submit" value="Send" disabled={submitting} />
                </FormFields>
              </form>
            </FormContainer>
          </ContactContainer>
        )}
        {success && (
          <SuccessContainer>
            <Mission>Success</Mission>
            <Text style={{ margin: '0 0 35px' }}>We&apos;ll get back to you shortly</Text>
          </SuccessContainer>
        )}
      </Center>
      {/*
    <iframe
      id="JotFormIFrame-230565839396167"
      title="General Inquiry Contact Form"
      allowFullScreen={true}
      allow="geolocation; microphone; camera"
      src="https://form.jotform.com/230565839396167"
      // style="min-width:100%;max-width:100%;height:539px;border:none;"
      style={{ background: 'white', border: 'none', marginBottom: '50px' }}
      width="600px"
      height="450px"
    >
    </iframe>
    <script type="text/javascript" dangerouslySetInnerHTML={{
      __html: `
      var ifr = document.getElementById("JotFormIFrame-230565839396167");
      if (ifr) {
        var src = ifr.src;
        var iframeParams = [];
        if (window.location.href && window.location.href.indexOf("?") > -1) {
          iframeParams = iframeParams.concat(window.location.href.substr(window.location.href.indexOf("?") + 1).split('&'));
        }
        if (src && src.indexOf("?") > -1) {
          iframeParams = iframeParams.concat(src.substr(src.indexOf("?") + 1).split("&"));
          src = src.substr(0, src.indexOf("?"))
        }
        iframeParams.push("isIframeEmbed=1");
        ifr.src = src + "?" + iframeParams.join('&');
      }
      window.handleIFrameMessage = function(e) {
        if (typeof e.data === 'object') { return; }
        var args = e.data.split(":");
        if (args.length > 2) { iframe = document.getElementById("JotFormIFrame-" + args[(args.length - 1)]); } else { iframe = document.getElementById("JotFormIFrame"); }
        if (!iframe) { return; }
        switch (args[0]) {
          case "scrollIntoView":
            iframe.scrollIntoView();
            break;
          case "setHeight":
            iframe.style.height = args[1] + "px";
            if (!isNaN(args[1]) && parseInt(iframe.style.minHeight) > parseInt(args[1])) {
              iframe.style.minHeight = args[1] + "px";
            }
            break;
          case "collapseErrorPage":
            if (iframe.clientHeight > window.innerHeight) {
              iframe.style.height = window.innerHeight + "px";
            }
            break;
          case "reloadPage":
            window.location.reload();
            break;
          case "loadScript":
            if( !window.isPermitted(e.origin, ['jotform.com', 'jotform.pro']) ) { break; }
            var src = args[1];
            if (args.length > 3) {
                src = args[1] + ':' + args[2];
            }
            var script = document.createElement('script');
            script.src = src;
            script.type = 'text/javascript';
            document.body.appendChild(script);
            break;
          case "exitFullscreen":
            if      (window.document.exitFullscreen)        window.document.exitFullscreen();
            else if (window.document.mozCancelFullScreen)   window.document.mozCancelFullScreen();
            else if (window.document.mozCancelFullscreen)   window.document.mozCancelFullScreen();
            else if (window.document.webkitExitFullscreen)  window.document.webkitExitFullscreen();
            else if (window.document.msExitFullscreen)      window.document.msExitFullscreen();
            break;
        }
        var isJotForm = (e.origin.indexOf("jotform") > -1) ? true : false;
        if(isJotForm && "contentWindow" in iframe && "postMessage" in iframe.contentWindow) {
          var urls = {"docurl":encodeURIComponent(document.URL),"referrer":encodeURIComponent(document.referrer)};
          iframe.contentWindow.postMessage(JSON.stringify({"type":"urls","value":urls}), "*");
        }
      };
      window.isPermitted = function(originUrl, whitelisted_domains) {
        var url = document.createElement('a');
        url.href = originUrl;
        var hostname = url.hostname;
        var result = false;
        if( typeof hostname !== 'undefined' ) {
          whitelisted_domains.forEach(function(element) {
              if( hostname.slice((-1 * element.length - 1)) === '.'.concat(element) ||  hostname === element ) {
                  result = true;
              }
          });
          return result;
        }
      };
      if (window.addEventListener) {
        window.addEventListener("message", handleIFrameMessage, false);
      } else if (window.attachEvent) {
        window.attachEvent("onmessage", handleIFrameMessage);
      }
    `
    }} /> */}
    </Container>
  );
}
