import * as emailjs from '../../node_modules/@emailjs/browser/es/index';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../components/ui/colors';
import { Text } from '../components/ui/basics';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.blue};
  padding: 30px 30px 10px;
  margin: 30px 0 50px;
`;
const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 10px;
    font-size: 1.5rem;
  }
  input {
    height: 33px;
    margin-bottom: 20px;
    width: 98%;
    font-size:1.2rem;
    border: 1px solid ${colors.blue};
  }
  input[type='submit'] {
    width: 100%;
    height: 45px;
    padding: 10px 0px;
    border: none;
    font-size: 1.2rem;
    background-color: ${colors.orange};
    color: white;
    font-weight: bold;
    &:hover {
      cursor: pointer;
    }
  }
`;

export function SubscriptionForm (): JSX.Element {
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: React.FormEvent): void => {
    e.preventDefault();
    setSubmitting(true);
    emailjs
      .sendForm(
        'service_94og1jb',
        'template_9h7oej8',
        form.current as HTMLFormElement,
        'E1HCO6unkGiVI5YvP'
      )
      .then(
        (result: { text: any }) => {
          setSuccess(true);
          console.log(result.text);
        },
        (error: { text: any }) => {
          setSubmitting(false);
          setError(error.text);
          console.log(error.text);
        }
      );
  };
  return (
      <>
        {!success && (
          <>
            <FormContainer>
              {error !== '' && <Text style={{ color: 'red' }}>{error}</Text>}
              <form ref={form} onSubmit={sendEmail}>
                <FormFields>
                  <input type="email" name="user_email" placeholder='roar@codetiger.io'/>
                  <input type="submit" value="Send" disabled={submitting} />
                </FormFields>
              </form>
            </FormContainer>
          </>
        )}
        {success && (
          <FormContainer>
            <h3>Success</h3>
            <Text>We&apos;ll get back to you shortly</Text>
          </FormContainer>
        )}
      </>
  );
}
