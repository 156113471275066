import React from 'react';
import styled from 'styled-components';
import { colors } from './ui/colors';
import { FacebookIcon } from './icons/facebookIcon';
import { InstagramIcon } from './icons/instagramIcon';
import { Link as RouterLink } from '../../node_modules/react-router-dom/dist/index';
import { LinkedinIcon } from './icons/linkedinIcon';
import { Orange } from '../App';
import { SROnly } from './ui/basics';

const Container = styled.div`
  padding: 30px;
  background: ${colors.gray};
`;
const FlexContainer = styled.div`
  display: flex;
  gap: 70px;
  align-items: start;
  @media only screen and (max-width: 600px) {
    gap: 30px;
  }
`;

const Heading = styled.p`
  font-size: 1.5rem;
  margin: 0;
`;

const Link = styled(RouterLink)`
  text-decoration: none;
  line-height: 2rem;
  color: black;
`;

const SocialMediaContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-left: auto;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const SocialLink = styled.a`
  color: black;
`;

const DesktopLinks = styled.div`
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const MobileLinks = styled.div`
  display: none;
  @media only screen and (max-width: 600px) {
    display: block;
  }
`;

export function Footer (): JSX.Element {
  return (
    <Container>
      <FlexContainer>
        <div>
          <Heading style={{ marginBottom: '10px' }}>
            code<Orange>tiger</Orange>
          </Heading>
          <MobileLinks>
            <Link to="/privacy">privacy</Link> <br />
            <Link to="/accessibility">accessibility</Link> <br />
            <Link to="/terms">terms</Link> <br />
          </MobileLinks>
        </div>
        <DesktopLinks>
          <Link to="/about">about us</Link> <br />
          <Link to="/how-it-works">how it works</Link> <br />
          <Link to="/jobs">jobs</Link> <br />
          <Link to="/blog">blog</Link> <br />
          <Link to="/contact">contact</Link> <br />
        </DesktopLinks>
        <div>
          <DesktopLinks>
            <Link to="/privacy">privacy</Link> <br />
            <Link to="/accessibility">accessibility</Link> <br />
            <Link to="/terms">terms</Link> <br />
          </DesktopLinks>
          <MobileLinks>
            <Link to="/about">about us</Link> <br />
            <Link to="/how-it-works">how it works</Link> <br />
            <Link to="/jobs">jobs</Link> <br />
            <Link to="/blog">blog</Link> <br />
            <Link to="/contact">contact</Link> <br />
          </MobileLinks>
        </div>
        <SocialMediaContainer>
          {/* <SocialLink href="" target="_blank">
            <TwitterIcon width={33} height={33} />
          </SocialLink> */}
          <SocialLink href="https://www.instagram.com/codetiger.bootcamp/" target="_blank">
            <InstagramIcon width={33} height={33} />
            <SROnly>instagram link</SROnly>
          </SocialLink>
          <SocialLink href="https://www.facebook.com/profile.php?id=100090708339920" target="_blank">
            <FacebookIcon width={33} height={33} />
            <SROnly>facebook link</SROnly>
          </SocialLink>
          <SocialLink href="https://www.linkedin.com/company/codetiger-school/" target="_blank">
            <LinkedinIcon width={33} height={33} />
            <SROnly>linkedin link</SROnly>
          </SocialLink>
        </SocialMediaContainer>
      </FlexContainer>
    </Container>
  );
}
