import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db, getSites } from './db/db'

export function Site (): JSX.Element {
  const { id } = useParams();

  const [site, setSite] = useState('');

  useEffect(() => {
    const get = async (): Promise<any> => {
      const sites = await getSites(db)
      setSite(sites.find((site: any) => site.name === id).html)
    }
    void get();
  }, [id])
  return (
    <div style={{ padding: '0 30px' }}>
      <div dangerouslySetInnerHTML={{ __html: site }}/>
    </div>
  );
}
