import React from 'react';
import styled from 'styled-components';
import { colors } from '../components/ui/colors';
import { FlexHorizontalCenter, Text } from '../components/ui/basics';
import { Orange } from '../App';

// const HeaderVerticalContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `

export const HeaderContainer = styled.div`
  padding: 50px 0;
`;

// const ContentContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `;

export const Row = styled.div`
  display: flex;
  padding: 0 15px;
  align-items: center;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;
export const Column = styled.div`
  width: 100%;
`;
export const Center = styled.div`
  text-align: center;
`;

export const HeaderCallout = styled.div`
  max-width: 500px;
`;

export const Heading = styled.h1`
  color: gray;
  margin: 0;
  font-size: 1.5rem;
  font-weight: normal;
`;

export const Mission = styled.h2`
  font-size: 2.5rem;
  margin: 0;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  @media only screen and (max-width: 600px) {
    display: flex;
    padding: 0 15px;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
`;

const ValueContainer = styled.div`
  padding: 30px 0;
`;
const ValueBox = styled.div`
  // border-radius: 50%;
  height: 100px;
  width: 200px;
  background-color: ${colors.darkblue};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  p {
  }
`;

export const container = styled.div``;
export function About (): JSX.Element {
  return (
    <>
      <HeaderContainer>
        <Row>
          <Column>
            <Center>
              <img src="/images/codetigerlogo.png" alt='logo' width="50%" />
            </Center>
          </Column>
          <Column>
            <HeaderCallout>
              <Heading>mission</Heading>
              <Mission>A Better Future<br />Through Education</Mission>
              <Text>
                code<Orange>tiger</Orange> is a school designed to provide
                personalized instruction and comprehensive support. we will help
                you achieve a stable future in a rapidly evolving
                economy
              </Text>
            </HeaderCallout>
          </Column>
        </Row>
      </HeaderContainer>

      <ValueContainer
        style={{ textAlign: 'center', background: colors.blue, color: 'white' }}
      >
        <Mission style={{ marginBottom: '25px', marginTop: '15px' }}>
          Values
        </Mission>
        <FlexHorizontalCenter
          style={{ marginBottom: '30px', flexWrap: 'wrap' }}
        >
          <ValueBox>
            <Text>honesty</Text>
          </ValueBox>
          <ValueBox>
            <Text>consistency</Text>
          </ValueBox>
          <ValueBox>
            <Text>hardwork</Text>
          </ValueBox>
        </FlexHorizontalCenter>
      </ValueContainer>

      <HeaderContainer>
        <Center>
          <h2>Founders</h2>
        </Center>
        <Row style={{ maxWidth: '800px', margin: 'auto' }}>
          <Column>
            <Flex>
              <img
                src="/images/mascot2.png"
                width="200px"
                alt="tiger mascot"
                // style={{ marginLeft: '-75px' }}
              />
              <div>
                <p>
                  fulbright scholar and nyu math + computer science. 7 years
                  experience as a software engineer working in edtech, crypto,
                  and weathtech
                </p>
                <p>
                  <strong>Mir N. Ali</strong>
                </p>
              </div>
            </Flex>
          </Column>
          <Column style={{ textAlign: 'right' }}>
            <Flex>
              {/* <HiddenDesktop> */}
                <img
                  src="/images/mascot1.png"
                  width="200px"
                  alt="tiger mascot"
                  // style={{ marginRight: '-75px' }}
                />
              {/* </HiddenDesktop> */}
              <div>
                <p>
                  digital marketing expert with experience in management,
                  education, and nonprofits
                </p>
                <p>
                  <strong>Krystal Zuniga</strong>
                </p>
              </div>
              {/* <HiddenMobile>
                <img
                  src="/images/mascot1.png"
                  width="200px"
                  // style={{ marginRight: '-75px' }}
                />
              </HiddenMobile> */}
            </Flex>
          </Column>
        </Row>
      </HeaderContainer>
    </>
  );
}
