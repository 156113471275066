import React from 'react';
import { Orange, TextContainer } from '../components/ui/basics';

const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export function ThankYou (): JSX.Element {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop as string)
  }) as any;

  // const name = params.invitee_full_name;
  const startDate = new Date(params.event_start_time);

  return (
    <>
      <TextContainer>
        <img
          src="/images/codetigerlogo.png"
          width={200}
          style={{ marginTop: '25px' }}
        />
        <h1>confirmed!</h1>
        <p>
          congratulations on taking your first step towards a lucrative journey
          of learning react! you&apos;ve made an important choice that can truly
          change your life
        </p>
        <p>
          code<Orange>tiger</Orange> is the only bootcamp where you learn one on
          one with real sr. engineers
        </p>
        <p>
          see you @{' '}
          {startDate.getHours() % 12 !== 0 ? startDate.getHours() % 12 : 12}:
          {startDate.getMinutes()} on {DAYS[startDate.getDay()]} (
          {startDate.getMonth() + 1}/{startDate.getDate()})
        </p>
      </TextContainer>
      {/* <PricingCards /> */}
      <TextContainer style={{ marginBottom: '50px' }}></TextContainer>
    </>
  );
}
