import React, { useEffect, useState } from 'react'
import { addSession, db, getSessions, getUsers } from './db/db'
import { classes, type User } from './CubDashboard'
import Modal from '../components/modal'

export interface Session {
  email: string
  title: string
  date: number
  summary: string
  suggestions: string
}

export default function CubDashboardAdmin (): JSX.Element {
  const [user, setUser] = useState<string>();
  const [users, setUsers] = useState<User[]>();
  const [sessions, setSessions] = useState<Session[]>();
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState<string>();

  const [summary, setSummary] = useState<string>();
  const [suggestions, setSuggestions] = useState<string>();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    void getUsers(db).then((users) => {
      setUsers(users);
    });
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (user) {
      void getSessions(db).then((sessions: Session[]) => {
        setSessions(sessions.filter(({ email }) => email === user));
      });
    }
  }, [user]);

  return (
    <div>
      <select onChange={(e) => { setUser(e.target.value); }}>
        <option value="" label="select user" />
        {users?.map(user => (
          <option key={user.email} value={user.email} label={`${user.email} (${user.name})`} />
        ))}
      </select>
      {sessions?.map(session => <></>)}
      {(user != null) && classes?.map(title => {
        const titles = sessions?.map(session => session.title);
        return <p key={title} onClick={() => { setModalOpen(true); setTitle(title); }}>{title} {((titles?.includes(title)) ?? false) ? <span>done</span> : <></>}</p>
      })}

      <Modal isOpen={modalOpen} onClose={() => { setModalOpen(false); }}>
        <h2>{title}</h2>
        <p>email: {user} </p>
        <p>Summary:</p>
        <textarea value={summary} onChange={(e) => { setSummary(e.target.value); }}/>
        <p>Suggestions:</p>
        <textarea value={suggestions} onChange={(e) => { setSuggestions(e.target.value); }}/><br />
        <button onClick={() => { setModalOpen(false); void addSession(user ?? '', title ?? '', Date.now(), summary ?? '', suggestions ?? ''); }}>submit</button>
        <button onClick={() => { setModalOpen(false); }}>close</button>
      </Modal>
    </div>
  )
}

/**
 * ability to add session with fields
 *
 */
