import React from 'react';
import styled from 'styled-components';
import { colors } from './ui/colors';
import { FlexContainer, Heading3 } from './ui/basics';

const PricingCard = styled.div`
  padding: 20px 30px 35px;
  border: 1px solid ${colors.darkblue};
  width: 360px;
  text-align: center;
  background: white;
`

const Price = styled.p`
  font-size: 1.75rem;
  margin: 15px 0 10px
`

const OriginalPrice = styled.span`
  font-size: 1rem;
  text-decoration: line-through;
  margin-right: 15px;
  color: gray;
`

const PriceDesc = styled.p`
  margin-bottom: 30px;
`

const SmallLinkButton = styled.a`
  color: white;
  background: ${colors.orange};
  padding: 10px 20px;
  text-decoration: none;
  font-size: 1.2rem;
  border-radius: 3px;
  border: 2px solid white;
`

export function PricingCards (): JSX.Element {
  return (
    <><FlexContainer>
      <PricingCard>
        <Heading3>upfront</Heading3>
        <Price>
          <OriginalPrice>$2400</OriginalPrice>$2200
        </Price>
        <PriceDesc>
          <strong>$200 off</strong> when you make a one time payment
        </PriceDesc>
        <SmallLinkButton href='https://buy.stripe.com/8wM8yufvs7XH5RSeV3' target='_blank'>start now</SmallLinkButton>
      </PricingCard>
      <PricingCard>
        <Heading3>monthly</Heading3>
        <Price>$800</Price>
        <PriceDesc>per month for 3 months ($2400 total)</PriceDesc>
        <SmallLinkButton href='https://buy.stripe.com/3cs1621ECgud4NOaEM' target='_blank'>start now</SmallLinkButton>
      </PricingCard>
    </FlexContainer><p style={{ textAlign: 'center' }}>
        {/* *our introductory pricing ends in 1 month. prices will increase to
        $2200 & $800 respectively */}
      </p></>
  )
}
