import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../components/ui/colors';
import { Button, Orange, Text } from '../components/ui/basics';
// import { PopupButton } from 'react-calendly';

const HeadingContainer = styled.div`
  background-color: ${colors.orange};
  color: white;
  min-height: 190px;
  padding-top: 35px;
`;
const Container = styled.div`
  max-width: 1100px;
  margin: auto;
  padding: 0 50px;
  @media only screen and (max-width: 768px) {
    padding: 0 20px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Column1 = styled.div`
  padding: 0 50px 0 0;
  @media only screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
`;

const Form = styled.div`
  height: auto;
  align-self: flex-start
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0px;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

// const UnorderedList = styled.ul`
//   margin-left: -20px;
//   li {
//     margin-bottom: 15px;
//     font-size: 1.15rem;
//     list-style: '✰ ';
//   }
// `;

const MainContent = styled.div`
  h2 {
    margin-top: 50px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 620px;
  }
`;

const InstructorCard = styled.div`
  margin-top: 50px;
  border: 1px solid ${colors.lightorange};
  border-radius: 5px;
  padding: 20px;
  h2 {
    margin-top: 0;
  }
`;

const P = styled.p`
  line-height: 1.5;
  font-size: 1.15rem;
  @media only screen and (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const StyledText = styled(Text)`
  margin-top: 10px;
  margin-bottom: 0px;
  @media only screen and (max-width: 768px) {
    font-size: 1.1rem !important;
  }
`;

const ScheduleContainer = styled.div`
  padding: 20px 0 50px;
  text-align: center;
  color: black;
  background: white;
  & button {
    border: none;
    color: white;
    background: ${colors.lightorange};
    padding: 15px 20px;
    text-decoration: none;
    font-size: 1.5rem;
    border-radius: 3px;
    font-weight: bold;
    margin-top: 15px;
    cursor: pointer;
  }
`;

const OriginalPrice = styled.span`
  font-size: 1rem;
  text-decoration: line-through;
  // margin-right: 15px;
  color: gray;
`;

const Card = styled.div`
  border: 1px solid gray;
  padding: 20px;
  border-radius: 2px;
`;

export function CubsMemberSyllabus (): JSX.Element {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange (): void {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 868;

  return (
    <>
      <HeadingContainer>
        <Container>
          {!isMobile && (
            <>
              <Row style={{ justifyContent: 'space-between' }}>
                <Column1 style={{ marginTop: '10px' }}>
                  <h1 style={{ marginBottom: '0' }}>Tiger Cub Syllabus 📚</h1>
                  <StyledText>
                    Objective: To spark a lasting interest in technology and
                    computer science
                  </StyledText>
                </Column1>
              </Row>
            </>
          )}
          {isMobile && (
            <Form style={{ color: 'black' }}>
              <img src="/images/cub-code.jpeg" style={{ width: '100%' }} />
              <div style={{ padding: '0 15px' }}>
                <h1 style={{ marginBottom: '0', fontSize: '2rem' }}>
                  Tiger Cub Syllabus 📚
                </h1>
                <Text style={{ marginTop: '10px', marginBottom: '0px' }}>
                  Objective: To spark a lasting interest in technology and
                  computer science
                </Text>
                <P>
                  Call / Text:{' '}
                  <a
                    href="tel:7323258451"
                    style={{ color: colors.lightorange }}
                  >
                    732-325-8451
                  </a>
                </P>
                <P>
                  Email:{' '}
                  <a
                    href="mailto: mir@codetiger.io"
                    style={{ color: colors.lightorange }}
                  >
                    mir@codetiger.io
                  </a>
                </P>
                <P>
                  <strong>Limited Seats</strong>: 3 students per week
                </P>
                <P style={{ marginBottom: '0' }}>
                  Price: <OriginalPrice>$500</OriginalPrice> $350
                </P>
                <p style={{ marginTop: '0' }}>
                  holiday discount ends January 1st
                </p>
              </div>
              {/* <ContactForm /> */}
              <ScheduleContainer>
                <Button
                  to="https://buy.stripe.com/6oE8yu8305Pza88bIU"
                  style={{ background: colors.lightorange }}
                >
                  Sign Up Today
                </Button>
              </ScheduleContainer>
            </Form>
          )}
        </Container>
      </HeadingContainer>
      <MainContent>
        <Container>
          <Column1 style={{ marginBottom: '75px' }}>
            <div>
              <h2>Week 1</h2>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '20px'
                }}
              >
                <Card style={{ width: '330px' }}>
                  <strong>Session 1</strong>:<br />
                  Introductions, Schedules, and How the Internet Works
                </Card>
                <Card style={{ width: '330px' }}>
                  <strong>Session 2</strong>:<br />
                  How Computers Work, What are Programming Languages, Intro to
                  Algorithms
                </Card>
                <Card style={{ width: '330px' }}>
                  <strong>Session 3</strong>:<br />
                  Intro to Scratch by MIT
                </Card>
              </div>
            </div>
            <div>
              <h2>Week 2</h2>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '20px'
                }}
              >
                <Card style={{ width: '330px' }}>
                  <strong>Session 1</strong>:<br />Building your First Game in
                  Scratch - Balloon Pop!
                </Card>
                <Card style={{ width: '330px' }}>
                  <strong>Session 2</strong>:<br />Second Scratch Game - Runaway
                  Apple
                </Card>
                <Card style={{ width: '330px' }}>
                  <strong>Session 3</strong>:<br />Design & Build Your Own Game
                </Card>
              </div>
            </div>
            <div>
              <h2>Week 3</h2>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '20px'
                }}
              >
                <Card style={{ width: '330px' }}>
                  <strong>Session 1</strong>:<br />Introduction to Python
                </Card>
                <Card style={{ width: '330px' }}>
                  <strong>Session 2</strong>:<br />Variables, Random Numbers, & Coin
                  Flips
                </Card>
                <Card style={{ width: '330px' }}>
                  <strong>Session 3</strong>:<br />Building a Text Based Game w/
                  Python
                </Card>
              </div>
            </div>
            <div>
              <h2>Week 4</h2>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '20px'
                }}
              >
                <Card style={{ width: '330px' }}>
                  <strong>Session 1</strong>:<br />How do Websites Works?
                </Card>
                <Card style={{ width: '330px' }}>
                  <strong>Session 2</strong>:<br />Introduction to HTML
                </Card>
                <Card style={{ width: '330px' }}>
                  <strong>Session 3</strong>:<br />Launching your First Website!
                </Card>
              </div>
            </div>
            <div>
              <h2>Week 5</h2>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '20px'
                }}
              >
                <Card style={{ width: '330px' }}>
                  <strong>Session 1</strong>:<br />Introduction to CSS
                </Card>
                <Card style={{ width: '330px' }}>
                  <strong>Session 2</strong>:<br />Introduction to JavaScript
                </Card>
                <Card style={{ width: '330px' }}>
                  <strong>Session 3</strong>:<br />Creating Interactive Websites
                </Card>
              </div>
            </div>
            <div>
              <h2>Week 6</h2>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '20px'
                }}
              >
                <Card style={{ width: '330px' }}>
                  <strong>Session 1</strong>:<br />Final Project Part 1
                </Card>
                <Card style={{ width: '330px' }}>
                  <strong>Session 2</strong>:<br />Final Project Part 2
                </Card>
                <Card style={{ width: '330px' }}>
                  <strong>Session 3</strong>:<br />Final Project Part 3
                </Card>
              </div>
            </div>
            <div id="instructor">
              <InstructorCard>
                <h2>Instructor</h2>
                <Row style={{ alignItems: 'start', gap: '25px' }}>
                  <img src="/images/mir.jpg" width="135px" />
                  <div>
                    <Row style={{ gap: '15px' }}>
                      <P style={{ marginTop: '-10px' }}>
                        🎓 NYU Computer Science & Math
                      </P>
                      {/* <P style={{ marginTop: '-10px' }}>🚀 Buildspace Alumi</P> */}
                      <P style={{ marginTop: '-10px' }}>🧑‍💻 Sr. Engineer</P>
                    </Row>
                    <P style={{ marginTop: '-10px' }}>
                      Mir has been coding for over a decade. He&apos;s a senior
                      engineer with over 5 years of professional experience in
                      education, crypto, and fintech. He&apos;s also a Fulbright
                      scholar and the founder of code
                      <Orange>tiger</Orange>
                    </P>
                  </div>
                </Row>
              </InstructorCard>
            </div>
          </Column1>
          {/* <h2>Reviews</h2> */}
          <div style={{ overflowX: 'scroll', marginBottom: '100px' }}>
            {/* <FlexHorizontalCenter>
              <InstructorCard style={{ marginTop: 0, minWidth: '270px' }}>
                <p style={{ marginTop: '0' }}>⭐️⭐️⭐️⭐️⭐️</p>
                <p>Josh Motley</p>
                <p>Software Engineer @ Instagram</p>
                <p>
                  Top tier engineers that were able to explain React,
                  Typescript, and Nextjs in an easy to understand way.
                </p>
              </InstructorCard>
              <InstructorCard style={{ marginTop: 0, minWidth: '270px' }}>
                <p style={{ marginTop: '0' }}>⭐️⭐️⭐️⭐️⭐️</p>
                <p>Kevin Kabore</p>
                <p>Software Engineer @ Peloton</p>
                <p>
                  I definitely recommend this bootcamp to anyone wanting to get
                  started with software engineering and development.
                </p>
              </InstructorCard>
              <InstructorCard style={{ marginTop: 0, minWidth: '270px' }}>
                <p style={{ marginTop: '0' }}>⭐️⭐️⭐️⭐️⭐️</p>
                <p>Raina Petrova</p>
                <p>Head of Engineering @ Coinme</p>
                <p>
                  Mir&apos;s as solid and reliable as they come; I trust him. As
                  an engineer, he&apos;s very smart and very capable. I
                  can&apos;t say enough good things.
                </p>
              </InstructorCard>
            </FlexHorizontalCenter> */}
          </div>
        </Container>
      </MainContent>
    </>
  );
}
