import React from 'react';
import { Mission } from './About';
import { Text, TextContainer } from '../components/ui/basics';

export function Terms (): JSX.Element {
  return (
    <>
      <Mission style={{ textAlign: 'center', marginTop: '30px' }}>Terms of service</Mission>
      <TextContainer>
        <Text>
          Codetiger Bootcamp is the owner and operator of codetiger.io (the
          “Site”), where you can find information about our products and
          services. These Website Terms and Conditions (the “Website Terms”)
          describe the rights and obligations of an unregistered website user or
          visitor regarding your use of the Site. By accessing or using the Site
          in any way, including as an unregistered website visitor, you agree to
          be bound by these Website Terms and our Privacy Policy located at
          https://www.codetiger.io/privacy. These Website Terms only apply to
          your use of the Site and the content made available on or through the
          Site as an unregistered website user or visitor. If you use or access
          any of our physical space, restricted-access web-based services (i.e.,
          those requiring a login), or other services we provide, your use of
          such space, services, or program is subject to the terms and
          conditions you received or accepted when you signed up for such space,
          services, or program. From time to time, we may make modifications,
          deletions, or additions to the Site or these Website Terms. Your
          continued use of the Site following the posting of any changes to the
          Website Terms constitutes acceptance of those changes.
        </Text>
        <Text>
          Eligibility. The Site and services it describes are only available to
          individuals who are at least 16 years old, unless we specify
          otherwise. No one under this age may access or use the Site or provide
          any personal information through the Site.
        </Text>
        <Text>
          Content. The text, images, videos, audio clips, software, and other
          content generated, provided, or otherwise made accessible on or
          through the Site (collectively, “Content”) are contributed by us and
          our licensors. The Content and the Site are protected by U.S. and
          international copyright laws. We and our licensors retain all
          proprietary rights in the Site and the Content made available on or
          through the Site, and, except as expressly set forth in these Website
          Terms, no rights are granted to any Content. Subject to these Website
          Terms, we grant each user of the Site a worldwide, non-exclusive,
          non-sublicensable, and non-transferable license to use (i.e., to
          download and display locally) Content solely for viewing, browsing,
          and using the functionality of the Site. All Content is for general
          informational purposes only. We reserve the right, but do not have any
          obligation to monitor, remove, edit, modify or remove any Content, in
          our sole discretion, at any time for any reason or for no reason at
          all.
        </Text>
        <Text>
          Disclaimer; Limitation of liability. To the extent permitted by law,
          we and our affiliates, parents, and successors and each of our and
          their employees, assignees, officers, agents, and directors disclaim
          all warranties and terms, express or implied, with respect to the
          Site, Content, or services (including third-party services) on or
          accessible through the Site, including any warranties or terms of
          merchantability, fitness for a particular purpose, title,
          non-infringement, and any implied warranties, or arising from a course
          of dealing, course of performance, or usage in trade. Under no
          circumstances shall we or our affiliates, parents, and successors and
          each of our and their employees, assignees, officers, agents, and
          directors be liable for any indirect, incidental, special, or
          consequential damages arising out of or in connection with your use of
          or inability to use the Site, Content, or services (including
          third-party services) on or accessible through the Site, whether based
          on warranty, contract, tort (including negligence), or any other legal
          theory, even if we have been advised of the possibility of such
          damages.
        </Text>
        <Text>
          Miscellaneous. These Website Terms shall be governed by and construed
          in accordance with the laws of the State of New Jersey, excluding its
          conflicts of law rules, and the United States of America. These
          Website Terms constitute the entire agreement between us regarding the
          Site and supersedes and merges any prior proposals, understandings and
          contemporaneous communications. If any provision of these Website
          Terms is held to be invalid or unenforceable, that provision shall be
          limited or eliminated to the minimum extent necessary so that these
          Website Terms shall otherwise remain in full force and effect and
          enforceable. In order for any waiver of compliance with these Website
          Terms to be binding, we must provide you with written notice of such
          waiver. The failure of either party to enforce its rights under these
          Website Terms at any time for any period will not be construed as a
          waiver of such rights.
        </Text>
        <Text>
          Contact. If you have any questions, complaints, or claims with respect
          to the Site, you may contact us at: codetiger.io / mir@codetiger.io
        </Text>
      </TextContainer>
    </>
  );
}
