import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from './ui/colors';
import { Link } from '../../node_modules/react-router-dom/dist/index';
import { SROnly } from './ui/basics';
// import { Countdown } from './countdown';

const Container = styled.div`
  padding-top: 3px;
`;
const AlertContainer = styled.div`
  padding: 0px 20px;
  border: 1px solid ${colors.darkblue};
  margin: 10px 20px;
  position: relative;
  background-color: white;
  text-align: center;
`;

const AlertMessage = styled.p`
  text-align: center;
`;

const CloseButton = styled.button`
  // margin-left: auto;
  position: absolute;
  right: 0;
  background: white;
  border: none;
  border-left: 1px solid ${colors.darkblue};
  border-bottom: 1px solid ${colors.darkblue};
  border-radius: 0;
  &:hover {
    cursor: pointer;
  }
`;

const StyledLink = styled(Link)`
  color: ${colors.orange};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const FirstRow = styled.div`
  display: flex;
  justify-content: center;
`;

// const CountdownContainer = styled.div`
//   margin-top: -10px;
//   margin-bottom: 15px;
// `

export function Alert ({
  message,
  link,
  linkDestination,
  assistiveText,
  hiddeable = false
}: {
  message: string | JSX.Element
  link?: string
  linkDestination?: string
  assistiveText?: string
  hiddeable?: boolean
}): JSX.Element {
  const [hide, setHide] = useState(false);

  return hide
    ? (
    <></>
      )
    : (
    <Container>
      <AlertContainer>
        <FirstRow>
          <AlertMessage>
            {message}{' '}
            {link != null && linkDestination != null && (
              <StyledLink
                to={linkDestination}
                target="_blank"
                onClick={(e) => {
                  hiddeable && setHide(true);
                }}
              >
                {link} <SROnly>{assistiveText}</SROnly>
              </StyledLink>
            )}
          </AlertMessage>
          <CloseButton
            onClick={() => {
              setHide(true);
            }}
          >
            x
          </CloseButton>
        </FirstRow>
        {/* <CountdownContainer>
        <Countdown />
        </CountdownContainer> */}
      </AlertContainer>
    </Container>
      );
}
