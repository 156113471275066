import React from 'react';
import { Mission } from './About';
import { Text, TextContainer } from '../components/ui/basics';

export function Accessibility (): JSX.Element {
  return (
    <>
      <Mission style={{ textAlign: 'center', marginTop: '30px' }}>Accessibility</Mission>

      <TextContainer>
        <Text>
          Our goal is to ensure that our website is accessible to all
          individuals. If you encounter any difficulties accessing any part of
          our website, please do not hesitate to reach out to us at
          mir@codetiger.io for assistance. Your feedback and suggestions are
          important to us as we continuously work towards enhancing the
          accessibility and usability of our website. Non-Discrimination Policy
        </Text>
        <Text>
          At Codetiger, we uphold a policy of non-discrimination towards
          students and prospective students. We do not discriminate based on
          actual or perceived age, marital or partnership status, pregnancy or
          maternity, caregiver status, race (including color, nationality,
          ethnicity or national origin), religion, creed, belief, sex, gender,
          sexual orientation, veteran or military status, presence of any
          sensory, mental or physical disability, use of a trained guide dog or
          service animal by a person with a disability, or any other legally
          protected status.
        </Text>
      </TextContainer>
    </>
  );
}
