import styled from 'styled-components';
import { colors } from './colors';
import { Link } from '../../../node_modules/react-router-dom/dist/index';

export const Heading2 = styled.h2`
  font-size: 2.25rem;
  font-weight: normal;
  // text-transform: uppercase;
  @media only screen and (max-width: 600px) {
    font-size: 1.75rem;
  }
`;

export const Heading3 = styled.h3`
  margin: 0;
  font-size: 1.5rem;
  font-weight: normal;
  @media only screen and (max-width: 600px) {
    font-size: 1rem;
  }
`;

export const PageContainer = styled.div`
  min-height: 60vh;
`;
export const Orange = styled.span`
  color: ${colors.orange};
`;
export const FlexHorizontalCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
`;
export const TextContainer = styled.div`
  max-width: 650px;
  text-align: center;
  margin: auto;
  @media only screen and (max-width: 600px) {
    padding: 0 15px;
  }
`;
export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
`;
export const Text = styled.p`
  font-size: 1.5rem;
`;

export const LinkButton = styled.a`
  color: white;
  background: ${colors.orange};
  padding: 15px 20px;
  text-decoration: none;
  font-size: 1.5rem;
  border-radius: 3px;
  font-weight: bold;
  margin-top: 15px;
  // border: 2px solid white;
`;

export const Button = styled(Link)`
  color: white;
  background: ${colors.orange};
  padding: 15px 20px;
  text-decoration: none;
  font-size: 1.5rem;
  border-radius: 3px;
  font-weight: bold;
  margin-top: 15px;
  // border: 2px solid white;
`;

export const HiddenMobile = styled.div`
  display: block;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;
export const HiddenDesktop = styled.div`
  display: none;
  @media only screen and (max-width: 600px) {
    display: block;
  }
`;

export const SROnly = styled.span`
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important; /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; /* 3 */
`;
