import { useEffect } from 'react';
import { useLocation } from '../../../node_modules/react-router-dom/dist/index';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function ScrollToTop () {
  const { pathname } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0
    });
  }, [pathname]);

  return null;
}
