import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  FlexContainer,
  FlexHorizontalCenter,
  Orange,
  Text,
  TextContainer
} from '../components/ui/basics';
import { colors } from '../components/ui/colors';
import { HalfContainer } from './Home';
import {
  Center,
  Column,
  Heading,
  HeaderCallout,
  HeaderContainer,
  Mission,
  Row
  // eslint-disable-next-line @typescript-eslint/indent
} from './About';
// import { PricingCards } from '../components/pricingCards'
// import styled from 'styled-components'

// const Container = styled.div`
//   text-align: center;
// `

const ListItem = styled.div`
  font-size: 1.5rem;
  padding: 20px;
  background-color: ${colors.blue};
  color: white;
  text-align: center;
  max-width: 125px;
`;

const Card = styled.div`
  max-width: 800px;
`;

// const ResponsiveImg = styled.img`
//   width: 50%;
//   @media only screen and (max-width: 600px) {
//     width: 100%;
//     margin-bottom: 35px;
//   }
// `;

const HiddenImg = styled.img`
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const VisibleImg = styled.img`
  display: none;
  @media only screen and (max-width: 600px) {
    display: block;
  }
`;

const FinalImg = styled.img`
  @media only screen and (max-width: 600px) {
    margin-bottom: 35px;
    margin-top: 50px;
  }
`;

const MobileSpacer = styled.div`
  @media only screen and (max-width: 600px) {
    margin-bottom: 50px;
  }
`;
export function HowItWorks (): JSX.Element {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange (): void {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  return (
    <>
      <HeaderContainer style={{ padding: '0px', marginBottom: '-3px' }}>
        <Row>
          <Column>
            <Center>
              <FinalImg src="/images/banner5.jpg" alt='2 girls reviewing a website on a laptop' width="100%" />
            </Center>
          </Column>
          <Column style={{ display: 'flex', justifyContent: 'center' }}>
            <HeaderCallout style={{ padding: '0 15px' }}>
            <Heading>what you&apos;ll learn</Heading>
              <Mission>Front End Web Development</Mission>
              <Text>
                when you go to a website, that&apos;s everything you see and
                interact with. think images, buttons, links, text, etc. entry
                level positions start at $115,000 per year while most
                experienced devs make up to $160,000 per year
              </Text>
            </HeaderCallout>
          </Column>
        </Row>
      </HeaderContainer>

      <HeaderContainer style={{ paddingTop: '0', paddingBottom: '0px' }}>
        <Center>
          <VisibleImg
            src="/images/coding.jpg"
            width="100%"
            alt='a young red head girl coding on dual monitors'
            style={{ marginBottom: '35px', marginTop: '50px' }}
          />
        </Center>
        <Row>
          <Column style={{ display: 'flex', justifyContent: 'center' }}>
            <HeaderCallout style={{ padding: '0 15px' }}>
              <div style={{ textAlign: 'right' }}>
                <Heading>the process</Heading>
                <Mission>Work like a Software Developer</Mission>
                <Text>
                  our course is designed to prepare you for a real job. tasks
                  are tracked through a project management system and code is
                  submitted and reviewed via source control.
                </Text>
              </div>
            </HeaderCallout>
          </Column>
          <Column>
            <Center>
              <HiddenImg src="/images/coding.jpg" alt='a young red head girl coding on dual monitors' width="100%" />
            </Center>
          </Column>
        </Row>
      </HeaderContainer>

      <HeaderContainer style={{ padding: '0px', marginBottom: '-3px' }}>
        <Row>
          <Column>
            <Center>
              <FinalImg src="/images/success.jpg" alt='a group of young kids huddled around a computer' width="100%" />
            </Center>
          </Column>
          <Column style={{ display: 'flex', justifyContent: 'center' }}>
            <HeaderCallout style={{ padding: '0 15px' }}>
              <Heading>certification</Heading>
              <Mission>Demonstrate your Skills</Mission>
              <Text>
                upon completion, you&apos;ll receive an NFT from code
                <Orange>tiger</Orange> certifiying your accomplishment.
                certificates will also be delivered in digital format and
                optionally in paper form
              </Text>
            </HeaderCallout>
          </Column>
        </Row>
      </HeaderContainer>
      <MobileSpacer />
      <HeaderContainer
        style={{
          background: colors.darkblue,
          color: 'white',
          paddingBottom: '85px'
        }}
      >
        <Center>
          <h2 style={{ marginBottom: '0px', fontSize: '2.5rem' }}>
            Course Schedule
          </h2>
        </Center>
        <Row style={{ padding: '0 15px' }}>
          <Column style={{ display: 'flex', justifyContent: 'center' }}>
            <Card>
              <Center>
                <Text>
                  we understand that life can be unpredictable, which is why our
                  course is designed to work with your schedule. you can
                  complete the course at your own pace, and there&apos;s no
                  additional cost for doing so. full-time students can complete
                  the course in as little as 12 weeks.
                </Text>
              </Center>
              <Center style={{ paddingTop: '20px' }}>
                <Button to="/schedule">learn more</Button>
              </Center>
            </Card>
          </Column>
        </Row>
        <Center>
          <TextContainer>
            {/* <Text>
              full-time the course can be completed in as little as 12 weeks
            </Text>
            <Text style={{ marginBottom: '50px' }}>
              the course is also designed to work with your schedule and can be
              completed at your own pace at no additional cost
            </Text> */}
          </TextContainer>
        </Center>
      </HeaderContainer>
      {/* <HeaderContainer>
        <Center>
          <Heading style={{ marginBottom: '30px' }}>Career Support</Heading>
        </Center>
        <FlexHorizontalCenter>
          <CareerCard>resume templates</CareerCard>
          <CareerCard>technical interview resources</CareerCard>
          <CareerCard>guaranteed internship</CareerCard>
        </FlexHorizontalCenter>
      </HeaderContainer> */}

      <FlexContainer
        style={{
          marginTop: '0px',
          justifyContent: 'center',
          marginBottom: isMobile ? '25px' : ''
        }}
      >
        <HalfContainer
          style={{ textAlign: 'center', width: isMobile ? '100%' : '57%' }}
        >
          <img
            src="/images/career.jpg"
            alt='a girl explaining something to a guy with a whiteboard'
            width="100%"
            height="100%"
            style={{ marginLeft: '-15px', marginBottom: '-5px' }}
          />
        </HalfContainer>
        <HalfContainer style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ maxWidth: '450px', padding: '50px', textAlign: 'center' }}>
            <Mission style={{ marginBottom: '35px' }}>Career Support</Mission>
            <FlexHorizontalCenter style={{ flexWrap: 'wrap' }}>
              <ListItem>guaranteed internship</ListItem>
              <ListItem>resume templates</ListItem>
              <ListItem>technical interview</ListItem>
            </FlexHorizontalCenter>
          </div>
        </HalfContainer>
      </FlexContainer>
    </>
  );
}
