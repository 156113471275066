import React, { useEffect, useState } from 'react';
import { addSite, db, getSites } from './db/db'

export function SiteGenerator (): JSX.Element {
  const [html, setHtml] = useState('<h1>Welcome to Site Builder</h1>\n<p>Enter HTML in the box on the left and see how it renders on the right</p>\n<p>Enter a name, hit save, and your website will go live instantly</p>');
  const [name, setName] = useState('');
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    void getSites(db);
  }, []);

  const save = async (): Promise<any> => {
    setSaved(true);
    await addSite(name, html);
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '25px' }}>
      <div style={{ width: '47%' }}>
        <textarea style={{ width: '100%', height: '50vh' }} onChange={(e) => {
          setHtml(e.target.value)
        }} value={html} />
        www.codetiger.io/site/
        <input type='text' value={`${name}`} onChange={(e) => { setName(e.target.value) }} style={{ width: '50%', height: '30px', marginTop: '20px' }} />
        <button style={{ width: '100px', height: '40px', marginTop: '20px', float: 'right' }} onClick={() => { void save() }}>save</button>
        {saved && (
          <>
            <p>View your site here: <a href={`www.codetiger.io/site/${name}/edit`}>www.codetiger.io/site/{name}</a></p>
            <p>Edit your site here: <a href={`www.codetiger.io/site/${name}/edit`}>www.codetiger.io/site/{name}/edit</a></p>
          </>
        )}
      </div>
      <div style={{ width: '47%', border: '1px solid black', height: '90vh', padding: '0 20px' }}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  );
}
