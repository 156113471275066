import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSites, db, addSite } from './db/db'

export function SiteEdit (): JSX.Element {
  const { id } = useParams();

  const [html, setHtml] = useState('');
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    const get = async (): Promise<any> => {
      const sites = await getSites(db)
      setHtml(sites.find((site: any) => site.name === id).html)
    }
    void get();
  }, [id])

  const save = async (): Promise<any> => {
    if (id != null) {
      setSaved(true);
      await addSite(id, html);
    }
  }
  return (
     <div style={{ display: 'flex', justifyContent: 'space-between', padding: '25px' }}>
      <div style={{ width: '47%' }}>
        <textarea style={{ width: '100%', height: '50vh' }} onChange={(e) => {
          setHtml(e.target.value)
        }} value={html} />
        www.codetiger.io/site/{id}
        <button style={{ width: '100px', height: '40px', marginTop: '20px', float: 'right' }} onClick={() => { void save() }}>save</button>
        {saved && (id != null) && (
          <>
            <p>View your site here: <a href={`www.codetiger.io/site/${id}/edit`}>www.codetiger.io/site/{id}</a></p>
          </>
        )}
      </div>
      <div style={{ width: '47%', border: '1px solid black', height: '90vh', padding: '0 20px' }}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  );
}
