import React from 'react';
import { Orange } from '../App';
import { Text, TextContainer } from '../components/ui/basics';

export function Charity (): JSX.Element {
  return (
    <TextContainer>
      <h1>Giving Back</h1>
      <Text>
        At code<Orange>tiger</Orange>, we believe that education should not just be a benefit
        to individuals, but should help make the world a better place. That&apos;s
        why we are committed to donating 100% of our profits to ecological
        initiatives that are working to restore the environment.
      </Text>
      <Text>We recognize
        that the health of our planet is inextricably linked to our own, and we
        strive to do our part to preserve it for future generations. Our
        donations have gone to help fund research, habitat rehabilitation, and other
        initiatives that are helping to ensure a more sustainable future.</Text>
        {/* We are
        proud to be part of this effort to support the environment, and to be
        part of the solution. */}
    </TextContainer>
  );
}
