import React, { useState } from 'react';
import styled from 'styled-components';
import { Center } from '../pages/About';
import { colors } from './ui/colors';
import { FlexHorizontalCenter } from './ui/basics';
const ScheduleCard = styled.div`
min-width: 200px;
  max-width: 350px;
  border: 1px solid ${colors.darkblue};
  margin-bottom: 20px;
`;

const WeekNumber = styled.p`
  font-size: 0.8rem;
`;

// const CareerCard = styled.div`
//   padding: 20px 20px;
//   border: 1px solid ${colors.darkblue};
// `;

const Shelf = styled.div`
  padding: 50px 30px;
`;

const Drawer = styled.div`
  max-width: 800px;
  margin: auto;
`;

const Handle = styled.div`
  padding: 10px 20px;
  border: 1px solid ${colors.darkblue};
  color: black;
  background-color: white;
  margin-bottom: 10px;
  // &:hover {
  //   background-color: ${colors.gray};
  //   cursor: pointer;
  // }
`;

const FlexWrap = styled(FlexHorizontalCenter)`
  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;
    gap: 10px;
  }
`

export function Schedule (): JSX.Element {
  const [drawer1Open] = useState(true);
  const [drawer2Open] = useState(true);
  const [drawer3Open] = useState(true);
  return (
    <Center>
      <Shelf>
        <Drawer>
          <Handle
          // onClick={() => {
          //   setDrawer1Open(!drawer1Open);
          //   setDrawer2Open(false);
          //   setDrawer3Open(false);
          // }}
          >
            month 1
          </Handle>
          {drawer1Open && (
            <FlexWrap>
              <ScheduleCard>
                <WeekNumber>week 1</WeekNumber>
                <p>js syntax</p>
              </ScheduleCard>
              <ScheduleCard>
                <WeekNumber>week 2</WeekNumber>
                <p>js syntax</p>
              </ScheduleCard>
              <ScheduleCard>
                <WeekNumber>week 3</WeekNumber>
                <p>ts fundamentals</p>
              </ScheduleCard>
              <ScheduleCard>
                <WeekNumber>week 4</WeekNumber>
                <p>html + css</p>
              </ScheduleCard>
            </FlexWrap>
          )}
        </Drawer>
        <Drawer>
          <Handle
          // onClick={() => {
          //   setDrawer2Open(!drawer2Open);
          //   setDrawer1Open(false);
          //   setDrawer3Open(false);
          // }}
          >
            month 2
          </Handle>
          {drawer2Open && (
            <FlexWrap>
              <ScheduleCard>
                <WeekNumber>week 5</WeekNumber>
                <p>react fundamental</p>
              </ScheduleCard>
              <ScheduleCard>
                <WeekNumber>week 6</WeekNumber>
                <p>let&apos;s build google</p>
              </ScheduleCard>
              <ScheduleCard>
                <WeekNumber>week 7</WeekNumber>
                <p>source control (git)</p>
              </ScheduleCard>
              <ScheduleCard>
                <WeekNumber>week 8</WeekNumber>
                <p>advanced react</p>
              </ScheduleCard>
            </FlexWrap>
          )}
        </Drawer>
        <Drawer>
          <Handle
          // onClick={() => {
          //   setDrawer3Open(!drawer3Open);
          //   setDrawer1Open(false);
          //   setDrawer2Open(false);
          // }}
          >
            month 3
          </Handle>
          {drawer3Open && (
            <FlexWrap>
              <ScheduleCard>
                <WeekNumber>week 9</WeekNumber>
                <p>advanced react</p>
              </ScheduleCard>
              <ScheduleCard>
                <WeekNumber>week 10</WeekNumber>
                <p>api based app</p>
              </ScheduleCard>
              <ScheduleCard>
                <WeekNumber>week 11</WeekNumber>
                <p>api based app</p>
              </ScheduleCard>
              <ScheduleCard>
                <WeekNumber>week 12</WeekNumber>
                <p>technical interview prep</p>
              </ScheduleCard>
            </FlexWrap>
          )}
        </Drawer>
      </Shelf>
    </Center>
  );
}
