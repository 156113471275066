import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../components/ui/colors';
import {
  Button,
  FlexHorizontalCenter,
  Orange,
  Text
} from '../components/ui/basics';
// import { PopupButton } from 'react-calendly';

const HeadingContainer = styled.div`
  background-color: ${colors.orange};
  color: white;
  max-height: 320px;
  padding-top: 35px;
`;
const Container = styled.div`
  max-width: 1100px;
  margin: auto;
  padding: 0 50px;
  @media only screen and (max-width: 768px) {
    padding: 0 20px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Column1 = styled.div`
  max-width: 650px;
  padding: 0 50px 0 0;
  @media only screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
`;

const Column2 = styled.div`
  flex-grow: 1;
  max-width: 380px;
  height: 2670px;
  border-radius: 5px;
  overflow: none;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const Form = styled.div`
  height: auto;
  align-self: flex-start
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0px;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

const UnorderedList = styled.ul`
  margin-left: -20px;
  li {
    margin-bottom: 15px;
    font-size: 1.15rem;
    list-style: '✰ ';
  }
`;

const MainContent = styled.div`
  h2 {
    margin-top: 50px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 540px;
  }
`;

const Card = styled.div`
  margin-top: 50px;
  border: 1px solid ${colors.lightorange};
  border-radius: 5px;
  padding: 20px;
  h2 {
    margin-top: 0;
  }
`;

const InstructorCard = styled.div`
  margin-top: 50px;
  border: 1px solid ${colors.lightorange};
  border-radius: 5px;
  padding: 20px;
  h2 {
    margin-top: 0;
  }
`;

const P = styled.p`
  line-height: 1.5;
  font-size: 1.15rem;
  @media only screen and (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const StyledText = styled(Text)`
  margin-top: 10px;
  margin-bottom: 0px;
  @media only screen and (max-width: 768px) {
    font-size: 1.1rem !important;
  }
`;

const ScheduleContainer = styled.div`
  padding: 20px 0 50px;
  text-align: center;
  color: black;
  background: white;
  & button {
    border: none;
    color: white;
    background: ${colors.lightorange};
    padding: 15px 20px;
    text-decoration: none;
    font-size: 1.5rem;
    border-radius: 3px;
    font-weight: bold;
    margin-top: 15px;
    cursor: pointer;
  }
`;

// const OriginalPrice = styled.span`
//   font-size: 1rem;
//   text-decoration: line-through;
//   // margin-right: 15px;
//   color: gray;
// `;

export function CubsLandingAdvanced (): JSX.Element {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange (): void {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 868;

  return (
    <>
      <HeadingContainer>
        <Container>
          {!isMobile && (
            <>
              <Row style={{ justifyContent: 'space-between' }}>
                <Column1 style={{ marginTop: '25px' }}>
                  {/* <Countdown /> */}
                  <h1 style={{ marginBottom: '0' }}>
                    Tiger Cub Bootcamp | Advanced 🐯
                  </h1>
                  <StyledText>
                    This advanced 6-week bootcamp trains students to build full
                    stack applications at scale
                  </StyledText>
                  <Row style={{ gap: '10px' }}>
                    {/* <p>may 15-17 @ 7pm est</p> */}
                    <p>
                      prerequisite:{' '}
                      <a href="/cubs/intermediate" style={{ color: 'white' }}>
                        intermediate
                      </a>
                    </p>
                    <p>
                      taught by{' '}
                      <a href="#instructor" style={{ color: 'white' }}>
                        mir
                      </a>
                    </p>
                    <p></p>
                  </Row>
                </Column1>
                <Column2>
                  <Form>
                    <img
                      src="/images/cub-code.jpeg"
                      style={{ width: '100%' }}
                    />
                    {/* <ContactForm /> */}
                    <ScheduleContainer>
                      <P>
                        Call / Text:{' '}
                        <a
                          href="tel:7323258451"
                          style={{ color: colors.lightorange }}
                        >
                          732-325-8451
                        </a>
                      </P>
                      <P>
                        Email:{' '}
                        <a
                          href="mailto: mir@codetiger.io"
                          style={{ color: colors.lightorange }}
                        >
                          mir@codetiger.io
                        </a>
                      </P>
                      <P>
                        <strong>Limited Seats</strong>: 3 students per week
                      </P>
                      <P style={{ marginBottom: '0' }}>
                        {/* Price: <OriginalPrice>$500</OriginalPrice> $350 */}
                        Price: $900
                      </P>
                      {/* <p style={{ marginTop: '0', marginBottom: '40px' }}>
                        holiday discount ends January 1st
                      </p> */}
                      <div style={{ height: '40px', width: '100%' }}></div>
                      <Button
                        to="https://buy.stripe.com/14kg0Wfvs2DneoocN4"
                        style={{ background: colors.lightorange }}
                      >
                        Sign Up Today
                      </Button>
                      {/* <p>or</p>
                      <P style={{ marginBottom: '0' }}>
                        fill out interest form for in-person classes
                      </P> */}
                    </ScheduleContainer>
                  </Form>
                </Column2>
              </Row>
            </>
          )}
          {isMobile && (
            <Form style={{ color: 'black' }}>
              <img src="/images/cub-code.jpeg" style={{ width: '100%' }} />
              <div style={{ padding: '0 15px' }}>
                <h1 style={{ marginBottom: '0', fontSize: '2rem' }}>
                  Tiger Cub Bootcamp 🐯
                </h1>
                <Text style={{ marginTop: '10px', marginBottom: '0px' }}>
                  This advanced 6-week bootcamp trains students to build full
                  stack applications at scale
                </Text>
                <p>
                  prerequisite:{' '}
                  <a href="/cubs/intermediate" style={{ color: 'white' }}>
                    intermediate
                  </a>
                </p>
                <P>
                  Call / Text:{' '}
                  <a
                    href="tel:7323258451"
                    style={{ color: colors.lightorange }}
                  >
                    732-325-8451
                  </a>
                </P>
                <P>
                  Email:{' '}
                  <a
                    href="mailto: mir@codetiger.io"
                    style={{ color: colors.lightorange }}
                  >
                    mir@codetiger.io
                  </a>
                </P>
                <P>
                  <strong>Limited Seats</strong>: 3 students per week
                </P>
                <P style={{ marginBottom: '0' }}>
                  {/* Price: <OriginalPrice>$500</OriginalPrice> $350 */}
                  Price: $900
                </P>
                {/* <p style={{ marginTop: '0' }}>
                  holiday discount ends January 1st
                </p> */}
              </div>
              {/* <ContactForm /> */}
              <ScheduleContainer>
                <Button
                  to="https://buy.stripe.com/14kg0Wfvs2DneoocN4"
                  style={{ background: colors.lightorange, marginTop: '50px' }}
                >
                  Sign Up Today
                </Button>
              </ScheduleContainer>
            </Form>
          )}
        </Container>
      </HeadingContainer>
      <MainContent>
        <Container>
          <Column1 style={{ marginBottom: '75px' }}>
            <div>
              <h2>What You&apos;ll Receive</h2>
              <UnorderedList>
                <li>
                  30% discount for other programs or robots offered by codetiger
                </li>
                <li>
                  Digital certificate and codetiger merchandise upon completion
                </li>
                <li>
                  Access to free parent workshops about coding and how to
                  support your child’s learning journey in an artifical
                  intelligence driven future
                </li>
                <li>
                  Access to guest lectures with engineers from top tech
                  companies
                </li>
                <li>
                  Family and friends discount - refer someone that signs up and
                  you both get a $50 Amazon gift card
                </li>
              </UnorderedList>
            </div>
            <Card>
              <h2>What Your Child Will Learn</h2>
              <UnorderedList>
                <li>Advanced programming patterns and techniques</li>
                <li>Database structures and interactions</li>
                <li>How to build fullstack applications that scale</li>
                <li>How to design intuitive user experiences</li>
                <li>Cybersecurity and authentication</li>
              </UnorderedList>
            </Card>
            <h2>Schedule</h2>
            <P>
              New bootcamps start every Monday. Classes meet 3 times a week for
              30 minutes and are done virtually. Students will have the option
              of joining our morning (11am) or evening (5:30pm) sessions Monday
              through Saturday.
            </P>
            <p>
              Times don&apos;t work for you? All good!{' '}
              <a href="tel:7323258451" style={{ color: colors.lightorange }}>
                Call us
              </a>{' '}
              to coordinate a custom schedule
            </p>
            <h2>
              Why Choose code<Orange>tiger</Orange>?
            </h2>
            <UnorderedList style={{ marginLeft: '-5px' }}>
              <li>
                Interactive Learning: Hands-on projects that bring coding to
                life.
              </li>
              <li>
                Expert Instructors: One on one sessions with senior software
                engineers.
              </li>
              <li>
                Safe & Inclusive Environment: A space where every child thrives.
              </li>
              <li>
                Flexible Scheduling: Weekday and weekend classes to fit your
                family&apos;s routine.
              </li>
            </UnorderedList>
            <h2>Special Features</h2>
            <UnorderedList style={{ marginLeft: '-5px' }}>
              <li>
                Live Online Classes: Interactive sessions from the comfort of
                your home.
              </li>
              <li>
                Progress Tracking: Regular updates on your child&apos;s learning
                journey.
              </li>
              <li>
                Community Building: Access to a community of young coders.
              </li>
              <li>
                Project Gallery: Kids will build a live website where they can
                share their projects with everyone.
              </li>
            </UnorderedList>
            <h2>Requirements</h2>
            <P>
              This course is for advanced students. We recommend that you
              complete the intermediate course before signing up for this one.
              All you need is a laptop + pencil & paper
            </P>
            <p>Recommended: MacBook</p>
            <h2>Description</h2>
            <P>
              In this advanced course, students will build full stack
              applications that are scalable. We will learn advanced programming
              optimization techniques and build intuitive features that have
              complex user interactions. Students will learn how to setup a
              database and build data-driven applications. They will learn the
              basics of authentication and cybersecurity.
            </P>
            <div id="instructor">
              <InstructorCard>
                <h2>Instructor</h2>
                <Row style={{ alignItems: 'start', gap: '25px' }}>
                  <img src="/images/mir.jpg" width="135px" />
                  <div>
                    <Row style={{ gap: '15px' }}>
                      <P style={{ marginTop: '-10px' }}>
                        🎓 NYU Computer Science & Math
                      </P>
                      {/* <P style={{ marginTop: '-10px' }}>🚀 Buildspace Alumi</P> */}
                      <P style={{ marginTop: '-10px' }}>🧑‍💻 Sr. Engineer</P>
                    </Row>
                    <P style={{ marginTop: '-10px' }}>
                      Mir has been coding for over a decade. He&apos;s a senior
                      engineer with over 5 years of professional experience in
                      education, crypto, and fintech. He&apos;s also a Fulbright
                      scholar and the founder of code
                      <Orange>tiger</Orange>
                    </P>
                  </div>
                </Row>
              </InstructorCard>
            </div>
          </Column1>
          <h2>Reviews</h2>
          <div style={{ overflowX: 'scroll', marginBottom: '100px' }}>
            <FlexHorizontalCenter>
              <InstructorCard style={{ marginTop: 0, minWidth: '270px' }}>
                <p style={{ marginTop: '0' }}>⭐️⭐️⭐️⭐️⭐️</p>
                <p>Josh Motley</p>
                <p>Software Engineer @ Instagram</p>
                <p>
                  Top tier engineers that were able to explain React,
                  Typescript, and Nextjs in an easy to understand way.
                </p>
              </InstructorCard>
              <InstructorCard style={{ marginTop: 0, minWidth: '270px' }}>
                <p style={{ marginTop: '0' }}>⭐️⭐️⭐️⭐️⭐️</p>
                <p>Kevin Kabore</p>
                <p>Software Engineer @ Peloton</p>
                <p>
                  I definitely recommend this bootcamp to anyone wanting to get
                  started with software engineering and development.
                </p>
              </InstructorCard>
              <InstructorCard style={{ marginTop: 0, minWidth: '270px' }}>
                <p style={{ marginTop: '0' }}>⭐️⭐️⭐️⭐️⭐️</p>
                <p>Raina Petrova</p>
                <p>Head of Engineering @ Coinme</p>
                <p>
                  Mir&apos;s as solid and reliable as they come; I trust him. As
                  an engineer, he&apos;s very smart and very capable. I
                  can&apos;t say enough good things.
                </p>
              </InstructorCard>
              {/* <InstructorCard style={{ marginTop: 0, minWidth: '270px' }}>
                  <p>Josh Motley</p>
                  <p>Software Engineer @ Instagram</p>
                </InstructorCard> */}
            </FlexHorizontalCenter>
          </div>
        </Container>
      </MainContent>
    </>
  );
}
