import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore';
const firebaseConfig = {
  apiKey: 'AIzaSyDJov4h25KGWWcmluUUxmpUUyyGoRSE9TE',
  authDomain: 'codetiger-app.firebaseapp.com',
  projectId: 'codetiger-app',
  storageBucket: 'codetiger-app.appspot.com',
  messagingSenderId: '380186418130',
  appId: '1:380186418130:web:57326c96e8e4c8aa2b060b',
  measurementId: 'G-38FQXRPKH0'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export const getSites = async (db: any): Promise<any> => {
  const col = collection(db, 'sites');
  const snapshot = await getDocs(col);
  const sites = snapshot.docs.map((doc) => doc.data());
  return sites;
}

export const getUsers = async (db: any): Promise<any> => {
  const col = collection(db, 'users');
  const snapshot = await getDocs(col);
  const users = snapshot.docs.map((doc) => doc.data());
  return users;
}

export const getSessions = async (db: any): Promise<any> => {
  const col = collection(db, 'sessions');
  const snapshot = await getDocs(col);
  const sessions = snapshot.docs.map((doc) => doc.data());
  return sessions;
}

export const addSite = async (name: string, html: string): Promise<any> => {
  try {
    const docRef = await addDoc(collection(db, 'sites'), {
      name,
      html
    });
    console.log('Document written with ID: ', docRef.id);
  } catch (e) {
    console.error('Error adding document: ', e);
  }
};

export const addUser = async (email: string, name: string): Promise<any> => {
  try {
    const docRef = await addDoc(collection(db, 'users'), {
      email,
      name
    });
    console.log('Document written with ID: ', docRef.id);
  } catch (e) {
    console.error('Error adding document: ', e);
  }
};

export const addSession = async (
  email: string,
  title: string,
  date: number,
  summary: string,
  suggestions: string
): Promise<any> => {
  try {
    const docRef = await addDoc(collection(db, 'sessions'), {
      email,
      title,
      date,
      summary,
      suggestions
    });
    console.log('Document written with ID: ', docRef.id);
  } catch (e) {
    console.error('Error adding document: ', e);
  }
};
